import React from "react";
import { Row, Col, List, Avatar, Typography, Space, Button } from "antd";
import MainLayout from "src/Layouts/MainLayout";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";

const { Title, Paragraph, Text, Link } = Typography;
const data = [
  {
    id: '1',
    title: 'A дуудлаганы хичээл',
    description: 'Энэхүү хичээл нь Англи хэлний хичээл',
    video_url: "https://www.youtube.com/watch?v=cSqqkZ3sG5k",
    avatar: "/images/profile/hulan.png",
  },
  {
    id: '2',
    title: 'B дуудлаганы хичээл',
    description: 'Энэхүү хичээл нь Англи хэлний хичээл',
    video_url: "https://collection.cloudinary.com/diabxernm/e782ac506262f13495e9faae88c4c06f?fbclid=IwY2xjawISlslleHRuA2FlbQIxMAABHSOGmow2RE-bTMFUZjGtGuNIHMty5vsedFRQ371tMhkeb_FHlZ9hs-OeEw_aem_U3Z2_kcw93ZEQVnFW5Tytg",
    avatar: "/images/profile/hulan.png"
  },
  {
    id: '3',
    title: 'С дуудлаганы хичээл',
    description: 'Энэхүү хичээл нь Англи хэлний хичээл',
    video_url: "https://collection.cloudinary.com/diabxernm/e782ac506262f13495e9faae88c4c06f?fbclid=IwY2xjawISlslleHRuA2FlbQIxMAABHSOGmow2RE-bTMFUZjGtGuNIHMty5vsedFRQ371tMhkeb_FHlZ9hs-OeEw_aem_U3Z2_kcw93ZEQVnFW5Tytg",
    avatar: "/images/profile/hulan.png"
  },
];

const LessonDetail: React.FC = () => {

   type Params = {
      id: string;
    };
    const { id } = useParams<Params>();
  
    const foundItem = data.find((item) => item.id === id );

  return (
    <MainLayout>
      <div
        style={{
          color: "#fff",
          minHeight: "calc(100vh - 64px)",
        }}
      >
        <Space
          direction="vertical"
          size="middle"
          style={{
            width: "100%",
            maxWidth: 1352,
            paddingTop: "104px",
            margin: "0 auto",
            display: "flex",
          }}
        >
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={8} lg={15} xl={15}>
            <div className="player-wrapper">
              <ReactPlayer
                url="https://collection.cloudinary.com/diabxernm/e782ac506262f13495e9faae88c4c06f?fbclid=IwY2xjawISlslleHRuA2FlbQIxMAABHSOGmow2RE-bTMFUZjGtGuNIHMty5vsedFRQ371tMhkeb_FHlZ9hs-OeEw_aem_U3Z2_kcw93ZEQVnFW5Tytg"
                width="100%"
                height="100%"
                controls={true}
              />
            </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={9} xl={9} span={12} style={{color:"#000"}}>
            <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item, index) => (
                  <List.Item key={item.id || index}>
                    <List.Item.Meta
                      avatar={<Avatar src={item.avatar || "/images/profile/hulan.png"} />}
                      title={<a href={item.id || "#"}>{item.title || "Untitled"}</a>}
                      description={item.description || "No description available"}
                    />
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </Space>
      </div>
    </MainLayout>
  );
};

export default LessonDetail;
