import React from "react";
import { Col, Typography, Row, Card, Avatar } from "antd";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;
const { Meta } = Card;

const data = [
  {
    id: 1,
    title: "А-Z видео хичээл",
    description:
      "Бага насны хүүхдэд зориулсан хичээл",
    image_src: "/images/lesson/lesson1.png",
  },
];

const LessonContainer: React.FC = () => {
  const navigate = useNavigate();

  const handleCardClick = (id: number) => {
    navigate(`/Lesson/${id}`);
  };

  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col>
          <Title style={{ fontSize: 24 }}>Видео хичээлүүд</Title>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        {data.map((item, index) => (
          <Col xs={24} sm={24} md={8} lg={8} xl={8} span={8} key={index}>
            <Card
              hoverable
              style={{ height: 345, marginBottom: 20 }}
              onClick={() => handleCardClick(item.id)}
              cover={<img src={item.image_src} />}
            >
              <Meta title={item.title} description={item.description} />
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default LessonContainer;
