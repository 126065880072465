import React, { useState } from "react";
import { Layout, Tabs, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import {
  HomeOutlined,
  TeamOutlined,
  FileTextOutlined,
  FileAddOutlined,
} from "@ant-design/icons";

const { Footer } = Layout;
const { TabPane } = Tabs;

interface Pane {
  title: string;
  key: string;
  className: string;
  icon: React.ReactNode;
}

const MobileMenu: React.FC = () => {
  const [activeKey, setActiveKey] = useState<string>("1");
  const [panes, setPanes] = useState<Pane[]>([
    {
      title: "Нүүр хуудас",
      key: "/",
      className: "mobile-menu",
      icon: <HomeOutlined />,
    },
    {
      title: "Подкаст",
      key: "/podcast",
      className: "mobile-menu",
      icon: <TeamOutlined />,
    },
    {
      title: "Англи хэлний хичээлүүд",
      key: "/lesson",
      className: "mobile-menu",
      icon: <TeamOutlined />,
    },
    {
      title: "Багш нарын блогууд",
      key: "/blogs",
      className: "mobile-menu",
      icon: <FileTextOutlined />,
    },
    {
      title: "Ном хэвлэл",
      key: "/download_file",
      className: "mobile-menu",
      icon: <FileAddOutlined />,
    },
  ]);

  const navigate = useNavigate();
  const onChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
    navigate(newActiveKey);
  };

  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mobile-menu">
          <Tabs onChange={onChange} activeKey={activeKey}>
            {panes.map((pane) => (
              <TabPane
                tab={
                  <span>
                    {pane.icon}
                    {pane.title}
                  </span>
                }
                style={{ marginLeft: 5 }}
                key={pane.key}
                closable={pane.key !== "1"}
              >
                <h1>{pane.title}</h1>
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default MobileMenu;
