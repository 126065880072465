import React from "react";
import MainLayout from "src/Layouts/MainLayout";
import { Tabs, Avatar, Typography, Image, Row, Col, Card } from "antd";

const { TabPane } = Tabs;
const { Meta } = Card;
const { Title, Text, Paragraph } = Typography;

const Blog12: React.FC = () => (
  <MainLayout>
    <div
      style={{
        width: "100%",
        maxWidth: 900,
        margin: "0 auto",
        minHeight: "calc(100vh - 64px)",
        marginTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Avatar
            size={100}
            src="/images/profile/battsetseg.png"
            style={{ marginRight: "20px" }}
          />
          <Text
            style={{
              fontSize: "18px",
              color: "#6B6B6B",
              wordSpacing: "2px",
              fontWeight: 700,
            }}
          >
            Багш: Батцэцэг
          </Text>
          <Title level={3}>Технологийн талаар хүүхдүүдтэйгээ ярилцаарай</Title>
          <Paragraph>
            Цаг үеэ дагаад хүүхэд төрөхөөсөө л технологийн орчинд амьдарч байна. Биднийг бага байхад гадаа гарах, найзуудтайгаа айл гэрдэж , дээсдэж эрэгтэй эмэгтэйгүй хамдаа хөгжилтэй тоглох нь хамгийн жаргалтай үе байсан. Харин одоо бага насны хүүхдийнхээ хоолыг идүүлэхийн тулд , хичээлийг нь  хийлгэхийн тулд тэр ч бүү хэл тэднийг чимээгүй суулгахын тулд бид гар утас болоод бусад дэлгэцийг шууд өгчихдөг болсон. Харамсалтай нь тэд найзтайгаа чатлаж  ML PubG тоглож , насандаа тохироогүй видео контент үзэж байгаа тэднийг Аз жаргалтай байлгадаг болчихсон. Гэтэл бид цахим хэрэглээ виртуал орчин хүүхдийн сэтгэц эрүүл мэндэд ямар их хортой вэ гэдгийг мэддэг хирнээ өгөөд л байгаа. Тэгэхээр эцэг эхчүүд бид хүүхэдтэйгээ энэхүү цахим хэрэглээгээр нь ойлголцож хяналт тавих хэрэгтэй . ЯАЖ? 
          </Paragraph>
          <Paragraph>
            <li>
                <b>Хамгийн эхний энгийн алхам бол гэр бүлдээ хүүхдүүдтэйгээ хамтран Гэрийн дүрэм зохиож зөрчсөн тохиолдолд хэрхэн шийтгэх  тухай ярилцаарай . Жишээ нь</b>
            </li>
          </Paragraph>
          <Paragraph>
            <b>1. </b> Хоолоо идэж байхдаа гар утас үзэхгүй: (учир нь хоолыг бид харж  амталж мэдэрч шингээж идэх ёстой  ингэсэнээр бид тархи ходоодоо хамтад нь ажиллуулж хоолоо шингээж байгаа гэдгийг ойлгуулан гэр бүлээрээ хооллож байхдаа утас үзэхгүй харин гэр бүлийн нандин сайхан яриа өрнүүлдэг байх  явдал юм.)
          </Paragraph>
          <Paragraph>
            <b>2. </b> Унтахын өмнөх утасны хэрэглээ: Хүүхэд хэдэн цагт унтах ёстой талаар хамтдаа уншиж судлаад 10 цагт унтах ёстой бол 9н цагт орондоо орж унтахаас өмнөх 1цагаас эхлэн утсаа хурааж интернетээ унтрааж хэвшүүлэх.
          </Paragraph>
          <Paragraph>
            <b>3. </b> Хүүхэд тань хэн нэгний тухай болон  ML PUBG YOUTUBE TICTOK гэх мэтийг үзэж байх үед нь эцэг эхчүүд түүнийг нь хамт сууж үзээд ямар мэдрэмж авч байгаагаа эрүүл мэндийн хувьд ямар мэдрэмж авч байгаагаа хэлээд ярилцах- ээж нь, аав нь  хамт сууж энэ тоглоомыг нь ажиглалаа миний толгой маш их өвдлөө нүд эрээлжиллээ чамд ч гэсэн ийм мэдрэмж төрж байгаан байна үүнийгээ багасгаарай гэх мэт ярилцах. Хэрэв эцэг эхчүүд завгүй бол өнөөдөр юу үзсэн, ямар тоглоом тоглосон талаар нь хоолны цагаараа ярилцаж байж агуулгыг нь мэдэж авна тэр ч бүү хэл ярилцсанаар аав ээж маань намайг сонсдог гэдэг итгэл үнэмшлийг олж авдаг.
          </Paragraph>
          <Paragraph>
            <b>4. </b>Эцэст нь эцэг эхчүүд хүүхэдтэйгээ ярилцаж зөвшилцөж зөвшөөрөл авч байгаад үзэж байгаа платформыг өөрийнхөө утаснаас хянуулах төхөөрөмжийг суулгаж авах ингэсэнээр хүүхэд тань аав ээж нь зөвшөөрөл авч байгаад баярлаж өөртөө итгэж байдаг . 
          </Paragraph>
          <Paragraph>
            Эрхэм хүндэт эцэг эхчүүд минь хүүхдийнхээ технологийн хэрэглээг зөвөөр хянаж зөвөөр ойлгуулж гэр бүлийн халуун дулаан уур амьсгалаа илүү сайхан болгоцгооё 
          </Paragraph>
        </Col>
      </Row>
    </div>
  </MainLayout>
);

export default Blog12;
