import React from "react";
import { Carousel, Card } from "antd";
import { Typography, Row, Col, List } from "antd";

const contentStyle: React.CSSProperties = {
  margin: 0,
  color: "#fff",
  lineHeight: "446px",
  textAlign: "center",
};

const training_programs = [
  "<b> Англи хэлний хөтөлбөр </b> - Яриа, бичиг, уншлага, дүрмийн гүнзгийрүүлсэн болон ерөнхий түвшний сургалтууд",
  "<b> Математикийн хөтөлбөр </b> – Математик, сэтгэх чадвар, бодлого бодох суурийг зөв тавих сургалтууд",
  "<b> Сэтгэлзүйн боловсрол </b> – EQ хөгжүүлэх сургалт, хүүхдийн сэтгэлзүйг дэмжих хөтөлбөр",
  "<b> Бага насны хүүхдийн хөгжлийн сургалт </b> – Багш, эцэг эхчүүдэд зориулсан хүүхдийн хөгжил, тархины үйл ажиллагаа, </br> танин мэдэхүй болон хэл, сэтгэхүйг хөгжүүлэх сургалтууд", 
  "<b> Хүүхдийн хоолны үйлчилгээ </b> – Иж бүрэн тоног төхөөрмж бүхий гал тогоогоор хүүхдэд зориулсан эрүүл, шим тэжээлтэй хоолны </br> цэсээр хангах үйлчилгээ"
]

const special_advantages = [
  "Туршлагатай, чадварлаг багш нар",
  "Интерактив, сонирхолтой хичээлүүд",
  "Орчин үеийн сургалтын арга зүй",
  "Хүүхэд нэг бүрд тохирсон хандлага",
  "Суралцагчдын ахицыг байнга хянаж, эцэг эхчүүдэд мэдээлэл өгөх тогтолцоо",
  "Англи хэл, Математик, Сэтгэл зүйн боловсрол олгох сургалтын бие даасан хөтөлбөртэй, сурах бичгүүдтэй.",
];

const HomeContainer: React.FC = () => {
  return (
    <>
      <Carousel 
        arrows={true} 
        infinite={false}
        autoplay>
        <div>
          <h3 style={contentStyle}>
            <img
              src="/images/slide/intro1.png"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                color: "#000000",
              }}
            />
          </h3>
        </div>
        <div>
          <h3 style={contentStyle}>
            <img
              src="/images/slide/intro2.png"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                color: "#000000",
              }}
            />
          </h3>
        </div>
        <div>
          <h3 style={contentStyle}>
            <img
              src="/images/slide/intro3.png"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                color: "#000000",
              }}
            />
          </h3>
        </div>
        <div>
          <h3 style={contentStyle}>
            <img
              src="/images/slide/intro4.png"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                color: "#000000",
              }}
            />
          </h3>
        </div>
        <div>
          <h3 style={contentStyle}>
            <img
              src="/images/slide/intro5.png"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                color: "#000000",
              }}
            />
          </h3>
        </div>
      </Carousel>
      <Row justify="center" align="middle" style={{width: "100%", margin: "0 auto",  maxWidth: 1300, marginTop: 20, marginBottom: 20}}>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <svg version="1.1" viewBox="0 0 960 836" width="120" height="100">
              <path className="svg-podcast" transform="translate(0)" d="m0 0h960v836h-960z"/>
              <path transform="translate(204,461)" d="m0 0h220l16 3 16 8 10 9 8 10 6 12 3 12 1 37v168l-1 28-4 13-9 14-7 8-14 9-9 4-13 3h-226l-16-4-13-7-12-11-9-14-4-11-1-5-1-14v-205l1-17 4-12 6-10 10-12 10-7 12-6z" fill="#FEFFFE"/>
              <path transform="translate(393,33)" d="m0 0 37 1 15 4 13 8 7 6 9 12 6 12 3 12 1 57v113l-1 63-4 13-9 14-8 9-14 9-9 4-11 2h-229l-15-4-12-7-10-9-8-10-6-13-2-10-1-15v-204l1-17 4-12 6-10 9-10 8-7 14-7 11-3z" fill="#FEFFFE"/>
              <path transform="translate(621,34)" d="m0 0h232l15 4 12 7 13 13 8 13 4 11 2 16v211l-2 16-5 13-9 13-7 7-14 9-13 4-10 1h-224l-10-2-10-4-12-8-9-9-7-11-4-10-2-10v-233l4-14 6-10 9-10 8-7 14-7z" fill="#FEFFFE"/>
              <path transform="translate(627,461)" d="m0 0h220l16 3 11 5 10 7 8 8 7 11 5 11 2 8 1 11v215l-2 12-5 12-7 11-6 7-13 9-8 4-10 3-8 1h-224l-16-4-14-8-10-9-6-8-6-12-3-13v-231l3-12 6-12 9-11 12-9 12-6z" fill="#FEFFFE"/>
              <path transform="translate(204,461)" d="m0 0h220l16 3 16 8 10 9 8 10 6 12 3 12 1 37v168l-1 28-4 13-9 14-7 8-14 9-9 4-13 3h-226l-16-4-13-7-12-11-9-14-4-11-1-5-1-14v-205l1-17 4-12 6-10 10-12 10-7 12-6zm2 26-12 3-10 6-9 11-4 9-1 149v65l2 16 6 12 6 7 10 6 10 3h220l11-3 10-7 7-8 5-12 1-5v-214l-2-12-5-10-5-6-9-6-8-3-6-1z" fill="#B26FC0"/>
              <path transform="translate(393,33)" d="m0 0 37 1 15 4 13 8 7 6 9 12 6 12 3 12 1 57v113l-1 63-4 13-9 14-8 9-14 9-9 4-11 2h-229l-15-4-12-7-10-9-8-10-6-13-2-10-1-15v-204l1-17 4-12 6-10 9-10 8-7 14-7 11-3zm-188 26-9 2-11 7-7 7-6 10-1 3-1 12v196l1 18 3 11 7 10 7 6 8 4 3 1 11 1 219-1 12-5 6-5 6-9 4-10 1-7v-211l-2-13-6-12-8-8-13-6-6-1z" fill="#B26FC0"/>
              <path transform="translate(627,461)" d="m0 0h220l16 3 11 5 10 7 8 8 7 11 5 11 2 8 1 11v215l-2 12-5 12-7 11-6 7-13 9-8 4-10 3-8 1h-224l-16-4-14-8-10-9-6-8-6-12-3-13v-231l3-12 6-12 9-11 12-9 12-6zm1 26-9 2-10 6-5 4-7 10-3 8v224l4 13 8 10 9 6 7 3 5 1h220l8-2 10-5 6-5 6-9 3-10 1-8v-208l-2-14-5-10-7-8-14-7-7-1z" fill="#B26FC0"/>
              <path transform="translate(621,34)" d="m0 0h232l15 4 12 7 13 13 8 13 4 11 2 16v211l-2 16-5 13-9 13-7 7-14 9-13 4-10 1h-224l-10-2-10-4-12-8-9-9-7-11-4-10-2-10v-233l4-14 6-10 9-10 8-7 14-7zm7 25-13 4-9 7-7 8-4 7-1 4v225l3 10 6 10 8 7 8 4 13 2h203l17-1 12-5 9-8 5-10 2-7 1-9v-206l-2-16-6-11-8-8-10-5-8-2z" fill="#B26FC0"/>
              <path transform="translate(668,553)" d="m0 0 7 1 8 6 58 58 7-6 55-55 7-4 7 1 5 5 3 6-1 8-6 7h-2l-1 3-8 7-31 31-7 8-5 4-1 2h-2l2 5 57 57 5 9-1 7-5 6-5 2-7-1-10-9-52-52-2-3-4 2-59 59-7 4-7-1-6-5-2-5 1-7 4-6 49-49 7-8 2-4-7-8-52-52-3-4-1-6 2-6 5-5z" fill="#B26FC0"/>
              <path transform="translate(312,90)" d="m0 0h6l6 4 3 5 1 90 2 1 90 1 6 6 1 3v7l-6 7-3 1-20 1h-71v90l-3 6-5 3h-9l-7-5-1-3-1-91h-73l-17-1-7-4-2-4v-8l5-7 2-1h92l1-93 4-5z" fill="#B26FC0"/>
              <path transform="translate(261,524)" d="m0 0 13 2 12 6 7 6 7 10 4 8 2 8v13l-4 12-6 9-9 9-14 7-8 2h-10l-13-4-11-7-8-8-7-14-1-5v-16l4-13 6-9 8-8 12-6z" fill="#B26FC0"/>
              <path transform="translate(366,645)" d="m0 0h14l13 4 11 7 8 10 5 12 1 4v17l-3 10-6 9-8 8-14 7-12 3-10-1-13-5-9-6-8-9-5-10-2-7v-17l4-11 6-9 5-5 8-6 10-4z" fill="#B26FC0"/>
              <path transform="translate(381,547)" d="m0 0h8l6 4 3 6-1 8-9 10-132 132-5 4-5 2-8-1-5-5-2-5 2-9 9-10 87-87h2v-2l8-7 38-38z" fill="#B26FC0"/>
              <path transform="translate(637,191)" d="m0 0h210l5 5 3 5-1 6-6 7-3 1-36 1h-164l-8-1-6-5-2-3v-8l4-6z" fill="#B26FC0"/>
              <path transform="translate(257,550)" d="m0 0 9 1 8 5 5 7v13l-4 7-5 4-5 2h-11l-7-4-6-7-1-3v-9l4-8 8-6z" fill="#FEFFFE"/>
              <path transform="translate(367,671)" d="m0 0h12l6 3 6 7 2 5v9l-4 7-7 6-10 2-8-2-7-6-3-6v-11l3-6 5-5z" fill="#FEFFFE"/>
              <path transform="translate(187,368)" d="m0 0h9l3 1 23 1 211-1 9-1-3 2-11 2h-229l-12-3z" fill="#BA84C6"/>
              <path transform="translate(614,368)" d="m0 0 9 1 16 1h218v1l-10 1h-224l-10-2z" fill="#BA84C6"/>
              <path transform="translate(634,212)" d="m0 0 6 1 205 1v1l-36 1h-164l-8-1z" fill="#BA84C6"/>
              <path transform="translate(304,560)" d="m0 0 2 4v13l-2 4-2-5 1-8v-7z" fill="#BA84C6"/>
            </svg>
            <Typography style={{ marginTop: 8, fontSize: "20px", fontWeight: 700}}>МАТЕМАТИК</Typography>
          </div>
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <svg version="1.1" viewBox="0 0 1176 1120" width="100" height="100" xmlns="http://www.w3.org/2000/svg" className="svg-language">
              <path className="svg-language" transform="translate(0)" d="m0 0h1176v1120h-1176z"/>
              <path transform="translate(86,124)" d="m0 0h494l13 4 10 7 9 10 5 10 2 8 1 129v35l13 1 27 4 32 8 36 12 24 11 18 10 24 16 13 10 10 8 15 14 21 21 9 11 11 15 12 19 10 18 13 27 8 21 9 31 5 28 2 17 1 15v32l-3 37 4 1h163l20 1 12 4 9 7 8 8 6 10 1 4 1 13v280l-2 14-6 10-9 9-10 6-8 3-6 1h-491l-14-5-10-7-7-7-6-10-2-7-1-8v-48l-37-6-25-5-25-7-27-10-20-10-27-16-17-12-14-11-11-10-8-7-17-17-7-8-12-15-15-22-14-24-11-23-9-24-8-28-5-24-3-23-1-13v-37l3-29 7-34 9-29 11-28 12-24 1-3-8 1h-210l-15-3-11-6-10-9-6-8-4-11v-297l3-10 7-10 9-8 8-5 5-2z" fill="#AD6EBC"/>
              <path transform="translate(401,161)" d="m0 0h162l15 1 3 4 1 9 1 265 3 11 15 24 14 22 16 26 10 16v2l-6-2-37-17-78-36-33-15-29-13-3-1-14-1h-343l-9-2-4-5-1-4v-276l2-5 5-2z" fill="#FEFFFE"/>
              <path transform="translate(561,667)" d="m0 0 5 1 14 7 36 16 34 16 33 15 28 13 33 15 3 1 359 1 8 1 3 6v282l-6 5-24 1h-452l-9-1-5-4-1-2-1-272-2-9-8-14-13-20-15-24-11-18-7-11z" fill="#FEFFFE"/>
              <path transform="translate(930,788)" d="m0 0h9l8 6 13 18 16 23 10 14 14 20 24 34 11 16 7 10 2 1-1-44 1-83 4-8 8-6h12l7 4 4 5 2 5v195l-4 8-7 4h-11l-8-4-8-9-14-20-16-23-12-17-14-20-12-17-10-14-10-15v-2h-2l-1 124-3 9-5 5-6 3h-9l-8-4-5-6-2-11v-185l3-7 7-6z" fill="#AE6FBC"/>
              <path transform="translate(680,788)" d="m0 0h126l7 2 5 5 4 7 1 9-5 8-6 5-6 2-112 1v53h93l9 3 5 6 3 7v8l-6 9-4 3-6 2-95 1 1 53h110l7 1 7 6 4 8 1 8-4 8-7 6-3 1h-139l-6-3-5-4-2-4-1-6v-188l3-9 7-6 2-1z" fill="#AE6FBD"/>
              <path transform="translate(400,197)" d="m0 0h124l10 2 8 6 3 6v8l-4 10-7 11-10 14-12 17-14 20-12 17-9 13-14 20-12 17-13 19-3 4h93l8 2 7 8 3 7-1 9-4 7-8 5-12 1h-123l-8-2-7-6-3-6v-10l6-12 24-34 14-20 12-17 10-14 16-23 12-17 14-20 2-4-20 1h-70l-10-3-7-6-3-6v-11l6-9 5-3z" fill="#AE6FBC"/>
              <path transform="translate(198,198)" d="m0 0h12l5 3 6 7 9 24 19 55 8 20 3 1h11l9 4 5 6 3 8-1 7-4 6-5 4-7 3 2 10 14 40 1 8-4 8-8 6-6 2-9-1-6-5-7-14-15-42-3-11-51 1-7 18-13 37-5 9-6 5-2 1h-10l-9-4-4-5-2-4v-7l5-16 11-31v-5l-9-4-5-6-2-4v-11l4-6 6-5 7-2h13l7-18 13-37 14-40 5-9 5-5z" fill="#AD6EBC"/>
              <path transform="translate(360,492)" d="m0 0 4 1h6l11 8 15 13 18 13 14 10-1 13-6 37-3 29-2 24h-109l-1-1 1-16 5-29 7-26 6-17 7-16 12-23 11-16z" fill="#FEFFFE"/>
              <path transform="translate(838,486)" d="m0 0 5 5 8 11 11 19 13 27 11 33 5 21 4 25 1 12-1 1-77 1h-26l-6-1-5-53-5-35-2-11 1-5 12-9 16-11 11-9 10-9 11-9z" fill="#FEFFFE"/>
              <path transform="translate(306,678)" d="m0 0h109l1 1 3 35 4 34 5 28v5l-12 9-17 12-10 8-7 7-8 7-10 9-5-5-10-15-10-17-12-26-7-19-8-29-5-30z" fill="#FEFFFE"/>
              <path transform="translate(566,772)" d="m0 0h15l1 4v176l-6-2-16-8-11-7-12-11-9-9-12-16-11-17-12-23-11-26-8-25-2-7 8-5 25-10 15-5 21-5z" fill="#FEFFFE"/>
              <path transform="translate(620,367)" d="m0 0 13 5 13 7 12 9 17 17 14 19 11 19 11 22 9 23 9 26v5l-12 6-30 11-4-2-7-10-14-23-15-24-12-20-11-18-3-6-1-4z" fill="#FEFFFE"/>
              <path transform="translate(735,557)" d="m0 0 5 1 6 46 2 25v11h-128v-53l1-1 22-3 28 13 16 7h12l8-5 4-7v-10l-6-12 21-8z" fill="#FEFFFE"/>
              <path transform="translate(463,557)" d="m0 0 5 1 25 10 24 7 32 7 32 4 1 1v49l-6-2-38-18-20-9-6-2h-8l-8 4-4 5-2 6 1 7 6 11v2h-43v-17l2-22 4-30z" fill="#FEFFFE"/>
              <path transform="translate(454,678)" d="m0 0h67l6 8 12 19 14 22 4 6v2l-12 3-31 7-24 7-15 6-9 4h-2l-3-12-5-34-2-22z" fill="#FEFFFE"/>
              <path transform="translate(437,820)" d="m0 0 3 1 6 20 6 16 12 26 14 24 13 19 5 7v2l-6-2-23-11-21-12-18-12-16-13-8-7-16-15 2-4 13-12 12-11 14-10z" fill="#FEFFFE"/>
              <path transform="translate(707,383)" d="m0 0 20 9 16 8 19 11 19 14 13 11 12 11 7 8v4l-11 9-12 12-13 10-10 8h-3l-4-10-7-21-12-27-12-22-13-20-8-12z" fill="#FEFFFE"/>
              <path transform="translate(787,678)" d="m0 0h107l1 1-1 18-2 16-1 1h-107v-29l1-6z" fill="#FEFFFE"/>
              <path transform="translate(676,678)" d="m0 0h71l1 1v15l-1 17-5-1-32-15-29-13-5-3z" fill="#FEFFFE"/>
              <path transform="translate(475,507)" d="m0 0 5 1 35 16 23 10 17 8 1 2h-7l-31-7-23-8-21-9-1-1 1-9z" fill="#FEFFFE"/>
              <path transform="translate(203,275)" d="m0 0 3 3 9 26v4h-23l1-6 6-16z" fill="#FEFFFE"/>
              <path transform="translate(436,492)" d="m0 0 4 3v4l-5-1-3-4z" fill="#FEFFFE"/>
            </svg>
          <Typography style={{ marginTop: 8, fontSize: "20px", fontWeight: 700 }}>АНГЛИ ХЭЛ</Typography>
          </div>
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <svg version="1.1" viewBox="0 0 980 1016" width="100" height="100" className="svg-book">
              <path className="svg-book" transform="translate(0)" d="m0 0h980v1016h-980z" />
              <path transform="translate(0)" d="m0 0h980v1016h-980zm457 85-37 4-30 5-28 7-33 11-24 10-15 7-16 8-21 12-21 14-19 14-17 14-12 11-8 7-21 21-7 8-12 14-14 19-18 27-8 13-8 14-11 23-9 21-9 25-9 32-6 29-3 20-2 19-1 18v33l2 31 4 30 5 26 9 34 10 29 13 30 13 25 12 20 13 19 12 16 9 11 12 14 11 12 28 28 11 9 16 13 18 12 13 8 25 14 19 10 25 11 30 11 21 6 32 7 25 4 27 3 31 2h11l42-3 33-5 27-6 28-8 25-9 23-10 16-8 19-10 22-14 22-15 17-13 11-10 8-7 15-14 12-12 7-8 13-15 12-16 13-19 12-19 13-23 13-28 10-25 8-24 8-32 5-28 3-24 1-11v-52l-4-40-5-30-6-26-9-29-10-27-10-23-10-19-10-18-16-24-14-19-9-11-11-13-16-17-13-13-8-7-11-10-17-13-14-10-22-14-26-15-27-13-31-12-28-9-24-6-35-6-38-3z" fill="#F5F5F5"/>
              <path transform="translate(361,339)" d="m0 0h19l29 3 19 4 19 7 17 9 11 8 10 9h2l5-5 8-7 13-8 14-7 21-7 21-4 22-2h17l30 4 22 6 13 5 17 9 9 7 9 8 9 12 6 12 3 13v295l-3 7-8 7-369 1h-80l-10-1-7-6-3-6-1-19v-254l1-28 4-13 7-13 11-12 12-9 19-10 20-7 25-6z" fill="#AE6CBC"/>
              <path transform="translate(479,51)" d="m0 0 41 1 31 3 36 7 25 6 26 8 22 8 24 10 16 8 21 11 18 11 22 15 15 11 18 14 15 13 17 16 7 8 5 5v2h2l7 8 13 15 10 13 12 17 11 17 12 21 12 22 13 29 13 36 8 28 7 33 4 27 2 21 1 23v35l-2 35-5 33-6 28-9 31-11 31-15 33-8 15-6 12-12 19-8 12-13 18-13 16-8 10-11 12-29 29-8 7-13 11-15 12-20 14-28 17-27 15-30 13-24 9-31 10-29 7-35 6-16 2-38 2h-29l-36-3-35-5-36-8-29-9-29-11-35-17-15-8-22-13-12-8-17-12-13-10-11-9-24-22-25-25-9-11-11-13-14-19-15-23-12-21-14-27-11-24-10-26-10-34-6-26-5-30-2-21-1-29v-29l1-37 3-26 7-33 8-30 11-32 10-24 12-25 12-22 15-24 14-20 14-18 11-12 7-8 6-7h2l2-4 19-19 11-9 13-11 16-12 18-13 17-11 22-13 25-13 23-10 36-12 27-8 30-7 33-5 22-2zm-22 34-37 4-30 5-28 7-33 11-24 10-15 7-16 8-21 12-21 14-19 14-17 14-12 11-8 7-21 21-7 8-12 14-14 19-18 27-8 13-8 14-11 23-9 21-9 25-9 32-6 29-3 20-2 19-1 18v33l2 31 4 30 5 26 9 34 10 29 13 30 13 25 12 20 13 19 12 16 9 11 12 14 11 12 28 28 11 9 16 13 18 12 13 8 25 14 19 10 25 11 30 11 21 6 32 7 25 4 27 3 31 2h11l42-3 33-5 27-6 28-8 25-9 23-10 16-8 19-10 22-14 22-15 17-13 11-10 8-7 15-14 12-12 7-8 13-15 12-16 13-19 12-19 13-23 13-28 10-25 8-24 8-32 5-28 3-24 1-11v-52l-4-40-5-30-6-26-9-29-10-27-10-23-10-19-10-18-16-24-14-19-9-11-11-13-16-17-13-13-8-7-11-10-17-13-14-10-22-14-26-15-27-13-31-12-28-9-24-6-35-6-38-3z" fill="#AE6CBC"/>
              <path transform="translate(357,372)" d="m0 0h31l21 3 21 6 16 8 10 7 9 10 4 9 1 239-5-2-15-8-20-8-21-5-25-3h-22l-23 3-21 5-20 8-16 8-4 1v-239l4-8 6-7 10-8 12-7 24-8 16-3z" fill="#FEFEFE"/>
              <path transform="translate(582,372)" d="m0 0h30l21 3 21 6 16 8 10 7 7 7 6 12 1 238-2 1-16-9-19-8-19-5-19-3-10-1h-23l-23 3-17 4-15 5-16 7-11 6h-2v-239l4-8 12-12 11-7 8-4 18-6 19-4z" fill="#FEFEFE"/>
              <path transform="translate(364,660)" d="m0 0h19l23 3 19 5 19 8 13 9 5 4v2l-65 1h-92l-19-1 2-4 14-10 15-7 16-5 22-4z" fill="#FEFEFE"/>
              <path transform="translate(588,660)" d="m0 0h19l23 3 19 5 19 8 13 9 5 4v2l-69 1h-87l-20-1 2-4 14-10 15-7 16-5 22-4z" fill="#FEFEFE"/>
            </svg>
            <Typography style={{ marginTop: 8, fontSize: "20px", fontWeight: 700 }}>НОМ ХЭВЛЭЛ</Typography>
          </div>
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <svg version="1.1" viewBox="0 0 1501 2048" width="100" height="110" xmlns="http://www.w3.org/2000/svg" className="svg-podcast">
              <path className="svg-podcast"  transform="translate(0)" d="m0 0h1501v2048h-1501z"/>
              <path transform="translate(131,758)" d="m0 0h11l18 3 16 5 16 8 12 8 12 11 10 12 9 15 6 14 4 14 3 23 4 51 4 31 10 47 8 27 9 26 11 25 12 25 10 19 13 21 13 19 13 17 11 14 12 13 11 12 20 20 8 7 12 11 17 13 13 10 24 16 26 15 36 18 29 12 31 11 30 8 35 7 34 5 28 2h35l31-2 42-6 30-6 27-7 33-11 24-10 25-12 19-10 28-17 19-13 21-16 14-11 12-11 8-7 10-9 11-11 7-8 7-7 11-14 14-18 18-27 15-25 12-23 12-25 12-31 8-26 7-30 6-33 4-31 5-61 3-16 6-15 8-15 8-10 10-11 14-11 16-9 16-6 15-3h21l25 5 15 6 15 8 12 9 8 8 12 16 11 21 6 20 1 7v29l-4 49-5 39-6 35-10 43-8 28-14 42-11 28-9 20-13 28-10 19-10 17-15 24-10 15-13 18-10 14-12 15-7 9-12 14-28 30-20 20-8 7-16 15-8 7-15 12-14 11-18 14-18 13-22 14-24 14-22 12-26 13-29 13-28 11-41 14-37 10-44 10-20 4v150l1 19 1 1 311 1 21 3 16 5 16 8 10 7 10 9 11 12 12 18 8 17 6 21 1 7v26l-5 21-6 16-8 15-7 9-9 10-13 11-14 9-16 8-13 4-12 2-14 1h-834l-20-3-18-5-17-8-16-11-11-10-5-5-8-9-9-14-8-19-4-17-1-7v-20l3-17 5-16 8-16 10-16 12-13 7-7 12-9 14-8 12-5 17-3 15-1 305-1v-64l-1-106-5-2-43-9-44-11-43-14-29-11-26-12-28-13-22-12-21-12-19-12-18-12-21-16-13-10-22-18-15-13-10-9-8-7-17-16-14-15-13-14-9-11-11-13-24-32-13-18-10-15-12-19-12-20-10-18-14-28-14-32-11-29-12-36-9-32-9-39-6-35-5-40-2-23-1-21v-23l2-17 4-14 6-15 9-15 11-12 1-2h2v-2l8-7 13-9 16-8 14-5z" fill="#AC6FBC"/>
              <path transform="translate(744,116)" d="m0 0h16l30 2 31 4 24 6 36 12 20 9 23 12 22 13 18 13 10 8 10 9 8 7 20 20 9 11 12 15 10 15 9 15 13 24 10 23 11 33 7 28 5 26 1 11-3 2h-159l-34 1-7 3-8 6-8 10-4 10-1 5v8l3 10 6 10 7 6 8 4 8 2 12 1h180l2 6v112l-2 2h-188l-13 2-10 6-8 10-4 10-1 4v10l4 12 7 9 10 7 16 5 188 1 1 6v110l-6 2-193 1-9 3-10 9-7 14-1 13 4 13 6 8 8 7 12 5 10 2 184 1-1 16-5 28-7 27-10 29-9 20-8 16-12 20-11 16-13 16-14 17-22 22-11 9-18 14-24 16-15 9-19 10-32 13-24 7-30 7-30 5h-37l-45-6-30-5-30-8-20-7-24-11-27-14-19-12-16-13-11-9-10-9-8-7-11-12-9-11-13-16-13-18-12-20-13-25-10-25-8-25-6-24-4-23-1-17h155l26-1 16-3 8-4 10-9 6-10 2-7v-12l-5-12-7-9-9-6-8-2-38-1h-155l-3-2-1-8v-107l61-1 110-1 20-1 11-3 8-4 6-5 7-10 3-10v-10l-4-11-6-10-7-6-10-4-9-1h-183l-7-1-1-54v-36l1-28 126-1 58-1 14-1 12-4 5-3 7-10 4-13v-8l-6-16-8-11-8-6-6-2-188-1-9-1v-11l5-28 6-25 8-26 11-25 14-27 9-15 12-17 12-16 11-12 7-8 17-17 11-9 12-10 19-13 16-10 24-13 19-9 25-9 24-7 23-5 36-4z" fill="#FDFDFD"/>
              <path transform="translate(735,40)" d="m0 0h32l30 2 35 5 27 6 21 6 23 8 27 12 25 13 24 15 17 12 19 14 11 9 12 11 8 7 23 23 9 11 12 15 16 24 13 21 12 22 13 29 11 30 8 28 5 24 4 27 3 33 1 21 1 391-1 27-3 37-5 30-6 26-9 29-7 19-8 19-8 16-11 21-14 22-8 11-9 12-12 14-11 12-12 13-16 16-11 9-16 13-28 20-24 14-23 12-16 8-25 10-28 9-26 6-30 5-44 5h-28l-38-4-35-6-31-8-33-11-23-10-27-13-18-10-27-18-16-12-13-11-20-18-19-19-7-8-11-13-14-19-15-23-14-24-14-29-11-28-7-24-7-28-5-28-6-63 1-418 2-32 4-27 7-30 8-27 10-27 11-24 10-20 16-27 15-22 14-17 9-11 9-10 23-23 11-9 12-10 20-15 21-14 20-12 18-10 24-11 27-10 27-8 27-6 26-4zm9 76-30 2-29 4-26 6-36 12-24 11-19 10-15 9-12 8-15 11-10 8-15 13-19 19-9 11-7 8-14 19-7 11-11 19-12 23-10 23-8 26-6 25-5 28v11l9 1 188 1 9 4 7 6 7 11 5 14v8l-4 13-8 11-12 5-4 1-14 1-58 1-126 1-1 28v36l1 54 7 1h183l13 2 10 6 7 9 5 11 1 4v10l-3 10-8 11-8 6-11 4-5 1-20 1-110 1-61 1v107l1 8 3 2h155l38 1 10 3 8 6 7 10 4 10v12l-4 11-7 9-10 8-13 4-8 1-26 1h-155l1 17 4 23 6 24 12 36 11 24 10 19 12 19 9 12 13 16 8 10 11 12 7 8 11 10 11 9 14 11 14 10 25 14 22 11 25 10 23 7 26 6 48 7 17 2h37l30-5 38-9 24-8 26-11 21-11 22-14 18-13 14-11 14-12 21-21 8-10 13-16 10-14 12-19 10-18 11-25 12-36 6-25 5-31v-8l-184-1-16-4-10-6-7-7-5-10-2-8 1-13 7-14 10-9 9-3 193-1 6-2v-110l-1-6-188-1-16-5-10-7-7-9-4-12v-10l3-10 5-8 6-7 12-6 10-1h188l2-2v-112l-2-6h-180l-17-2-9-4-6-4-6-7-5-11-1-5v-8l4-13 8-11 7-6 5-3 4-1 34-1h159l3-2-2-17-7-33-9-31-8-22-12-26-13-23-11-17-12-16-9-11-12-13-18-18-8-7-15-12-20-14-25-14-19-10-23-10-31-10-24-6-31-4-30-2z" fill="#AC6FBC"/>
              <path transform="translate(131,832)" d="m0 0h10l9 3 9 7 6 7 5 11 2 19 2 34 6 48 6 33 10 38 15 44 12 29 17 35 12 21 15 24 11 16 14 19 9 12 11 13 11 12 9 10 28 28 8 7 14 12 13 11 32 24 25 16 21 12 38 19 28 12 37 13 23 7 26 6 38 7 37 5 41 4h27l53-6 39-6 34-7 29-8 37-13 24-10 24-11 26-13 17-10 18-11 17-12 28-22 17-14 15-13 7-7 8-7 15-15 7-8 9-10 9-11 13-16 24-34 18-30 9-16 8-16 9-19 11-27 13-38 7-25 7-29 6-37 4-36 3-43 2-12 4-10 7-7 10-5 16-2 14 3 9 6 7 10 3 12v23l-2 30-4 36-7 46-9 39-9 31-10 29-12 29-17 36-13 24-17 28-10 16-12 17-14 18-8 11-9 10-7 8-15 16-9 10-22 22-8 7-13 12-14 11-16 13-11 8-18 13-15 10-30 18-18 10-42 21-27 11-25 9-29 9-36 9-35 7-36 5-32 3-19 1h-54l-27-2-45-6-36-7-44-11-40-13-28-11-20-9-25-12-26-14-20-12-19-12-17-12-17-13-13-10-28-24-13-12-12-11-14-15-9-10-7-8-10-11-9-11-10-13-14-19-12-18-12-19-15-26-15-29-14-31-9-24-12-36-7-25-7-29-7-39-5-37-2-24-1-20v-20l3-9 7-11 8-8 9-5z" fill="#FDFDFD"/>
              <path transform="translate(408,1835)" d="m0 0h647l111 1 15 2 14 7 7 7 7 12 3 12-1 14-4 10-6 9-8 7-8 4-8 2-17 1h-821l-15-3-10-5-10-9-6-10-3-10v-13l5-13 7-9 10-8 8-4 6-2 8-1z" fill="#FDFDFD"/>
              <path transform="translate(717,1596)" d="m0 0h70l1 1v160l-1 3h-67l-3-2-1-3v-156z" fill="#FDFDFD"/>
            </svg>
            <Typography style={{ marginTop: 8, fontSize: "20px", fontWeight: 700 }}>ПОДКАСТ</Typography>
          </div>
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} className="svg-advice">
            <svg version="1.1" viewBox="0 0 996 1072" width="100" height="110" xmlns="http://www.w3.org/2000/svg">
              <path className="svg-advice" transform="translate(0)" d="m0 0h996v1072h-996z"/>
              <path transform="translate(617,103)" d="m0 0h39l30 4 30 8 24 9 16 8 11 6 17 11 12 9 10 9 8 7 19 19 8 10 10 14 11 18 8 15 11 27 7 25 5 27 2 22v26l-3 26-6 27-10 30-6 13-8 16-11 18-9 12-9 11-15 16-12 11-17 17-13 17-8 13-8 15-6 15-7 27-3 23-1 78-5 6-5 3-4 1-15 1-3 30-4 14-8 15-11 12-11 8-16 8-8 3-1 23-1 44-4 6h-2v2l-8 2h-259l-118 1-26 12-108 54-18 8-8 1-6-4-4-5-1-4-1-62-61-1-8-3-4-5v-471l2-5 3-2 11-1 362-1-4-16-5-19-4-24-2-21v-24l2-21 4-23 7-25 10-25 12-23 10-16 12-16 22-24 14-13 16-12 17-11 16-9 22-10 25-8 22-5z" fill="#FEFEFE"/>
              <path transform="translate(618,134)" d="m0 0h35l23 3 23 5 21 7 25 12 17 10 14 10 14 12 6 5v2l4 2 7 8 10 12 12 17 9 15 9 19 8 22 6 26 3 20 1 12v22l-3 24-5 23-9 27-11 23-9 15-12 16-10 11-7 8-8 8-8 7-13 13-9 11-11 15-9 15-8 16-8 20-6 21-4 22-2 10h-61l-1-19v-84l1-64 9-4 17-8 10-8 9-10 8-13 5-15 1-5v-23l-4-15-8-16-9-11-9-8-10-6-14-5-8-2-17-1-17 3-12 5-11 6-10 9-8 10-8 16-4 16v19l3 14 5 12 7 11 10 11 14 9 11 5 9 4 1 6v160l-1 1h-60l-2-6-5-27-5-18-8-20-8-16-9-16-9-12-8-10-12-13-9-9-8-7-13-13-11-14-8-11-10-17-11-23-7-20-5-19-3-18-2-25v-14l2-24 5-23 7-22 7-17 11-21 13-19 12-14 11-12 6-6h2v-2l11-9 13-10 18-11 16-8 14-6 19-6 28-6z" fill="#FEFEFE"/>
              <path transform="translate(617,103)" d="m0 0h39l30 4 30 8 24 9 16 8 11 6 17 11 12 9 10 9 8 7 19 19 8 10 10 14 11 18 8 15 11 27 7 25 5 27 2 22v26l-3 26-6 27-10 30-6 13-8 16-11 18-9 12-9 11-15 16-12 11-17 17-13 17-8 13-8 15-6 15-7 27-3 23-1 78-5 6-5 3-4 1-15 1-3 30-4 14-8 15-11 12-11 8-16 8-8 3-1 23-1 44-4 6h-2v2l-8 2h-259l-118 1-26 12-108 54-18 8-8 1-6-4-4-5-1-4-1-62-61-1-8-3-4-5v-471l2-5 3-2 11-1 362-1-4-16-5-19-4-24-2-21v-24l2-21 4-23 7-25 10-25 12-23 10-16 12-16 22-24 14-13 16-12 17-11 16-9 22-10 25-8 22-5zm1 31-26 4-24 6-22 8-17 8-18 11-12 9-15 12v2l-4 2-12 12-18 22-11 17-12 23-7 18-7 25-3 15-2 24v14l2 25 4 22 6 22 10 24 12 23 8 12 11 14 12 13 11 11 8 7 11 11 9 11 10 13 9 15 8 15 8 19 6 20 5 25 2 11 1 1h60l1-1v-160l-2-7-15-6-11-6-10-8-7-8-8-13-5-13-2-11v-19l4-16 8-16 8-10 7-7 15-9 15-5 13-2 17 1 15 4 14 7 9 7 10 11 8 14 5 15 1 5v23l-5 18-7 12-8 10-9 8-10 6-11 5-9 4-1 64v84l1 19h61l2-10 4-22 7-24 10-24 10-18 12-17 11-13 7-8 13-13 8-7 8-8 9-11 7-8 12-18 12-23 8-21 6-21 4-21 2-18v-22l-3-27-6-27-7-21-8-18-8-15-11-16-10-13-11-12-3-4h-2l-1-3-8-7-16-13-16-10-18-10-17-8-21-7-29-6-17-2zm-543 348-32 1v420l2 6 64 1 5 3 3 4 1 3 2 55 5-1 17-9 23-11 46-23 19-10 23-11 367-1 2-2v-21l-1-23-5-3-13-5-13-8-10-9-10-15-5-12-3-17-2-21-1-1-14-1-9-3-4-5-1-11-1-61-1-17-4-22-5-16-6-16-11-21-9-13-9-11-14-15-15-14-11-11-9-11-12-16-8-13-6-10-2-2-117-1z" fill="#AF6DBE"/>
              <path transform="translate(100,818)" d="m0 0h314l6 5 3 6v9l-3 5-7 5-6 1-107 1h-193l-9-3-5-5-3-4-1-8 3-6 5-5z" fill="#AF6DBE"/>
              <path transform="translate(101,544)" d="m0 0h313l5 3 5 10-1 9-7 8-5 1h-306l-9-2-5-5-1-3v-11l4-7 2-2z" fill="#AF6DBE"/>
              <path transform="translate(101,636)" d="m0 0h313l6 5 4 10-2 7-4 5-3 2-7 1h-305l-8-3-4-5-1-2v-11l3-5 4-3z" fill="#AF6DBE"/>
              <path transform="translate(101,727)" d="m0 0h311l5 2 5 6 2 9-3 6-4 5-8 2h-307l-7-3-5-7v-10l4-7z" fill="#AF6DBE"/>
              <path transform="translate(630,392)" d="m0 0h13l13 4 11 7 7 8 6 12 2 7v17l-5 13-8 10-10 8-12 5h-21l-12-5-9-7-6-7-6-12-2-9v-13l4-13 7-9 5-5 9-6 9-4z" fill="#FEFEFE"/>
              <path transform="translate(595,772)" d="m0 0h87l1 1v14l-3 16-5 10-9 10-11 7-11 3h-16l-10-3-11-7-8-9-5-9-3-13v-19z" fill="#FEFEFE"/>
              <path transform="translate(561,712)" d="m0 0h151l1 1v24l-1 5h-150l-2-7v-22z" fill="#FEFEFE"/>
              <path transform="translate(935,132)" d="m0 0 9 1 6 5 2 4v12l-4 5-15 9-29 16-9 4h-9l-5-3-4-5-1-3v-8l4-7 13-8 29-16z" fill="#AF6DBE"/>
              <path transform="translate(427,58)" d="m0 0h8l8 4 35 35 6 10v9l-6 8-6 2-8-1-6-4-6-5-25-25-8-10-3-8 2-8 7-6z" fill="#AF6DBE"/>
              <path transform="translate(333,132)" d="m0 0h6l31 16 17 10 9 8 2 4v6l-3 6-7 6h-10l-16-8-24-13-12-8-4-5-1-2v-10l4-6z" fill="#AF6DBE"/>
              <path transform="translate(839,58)" d="m0 0h7l6 4 4 5 1 9-3 6-9 10-31 31-7 3-8-1-6-3-4-8v-7l4-7 38-38z" fill="#AF6DBE"/>
              <path transform="translate(299,271)" d="m0 0h61l5 3 4 5 2 5-1 7-4 6-4 3-4 1h-58l-7-3-4-5-2-5 1-8 4-6z" fill="#AF6DBE"/>
              <path transform="translate(914,271)" d="m0 0h61l6 3 2 3 1 5v11l-4 6-7 2h-57l-7-3-5-6-1-2v-9l4-6 4-3z" fill="#AF6DBE"/>
            </svg>
            <Typography style={{ marginTop: 8, fontSize: "20px", fontWeight: 700 }}>ЗӨВӨЛГӨӨ</Typography>
          </div>
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}  className="svg-location">
            <svg version="1.1" viewBox="0 0 888 980" width="100" height="110" xmlns="http://www.w3.org/2000/svg">
            <path className="svg-location" transform="translate(0)" d="m0 0h888v980h-888z"/>
            <path transform="translate(0)" d="m0 0h888v980h-888zm451 12-31 3-29 6-24 7-21 8-30 14-21 13-17 12-17 14-12 12-6 5-7 8-11 12-13 17-10 15-11 19-8 16-6 13-9 24-7 25-5 27-2 19-1 23 2 27 5 26 8 27 7 20 10 24 12 26 13 26 12 21 6 11 17 29 16 26 19 28 16 24 8 11 13 19 14 19 15 22-14 1-46 7-36 7-30 7-31 9-25 9-29 13-18 11-13 10-10 9-6 7-7 11-4 9-1 6v25l5 14 8 12 9 10 16 13 15 9 21 11 28 11 31 10 28 7 38 8 32 5 50 6 57 4 38 1h30l37-1 45-3 54-6 35-5 34-7 29-7 24-7 29-10 18-8 20-10 15-10 9-7 13-12 9-14 5-12 2-9v-9l-3-14-5-12-8-11-9-10-12-10-17-11-16-8-17-8-27-10-30-9-44-10-47-8-31-4h-5l4-7 10-13 12-17 13-18 15-22 18-27 9-14 15-24 24-40 13-24 10-19 13-27 14-33 12-36 6-25 3-19v-39l-3-26-4-23-7-26-8-22-9-20-10-19-11-18-12-17-11-13-12-13-9-10-8-7-10-9-17-13-15-10-17-10-16-8-20-9-19-7-26-7-30-5-26-2z" fill="#F5F5F5"/>
            <path transform="translate(451,12)" d="m0 0h29l26 2 30 5 32 9 27 11 19 9 17 10 15 10 16 12 14 12 15 14 7 8 11 12 13 17 11 17 12 21 11 23 9 24 7 25 4 19 3 22 1 12v39l-4 23-7 28-10 29-12 28-12 26-12 24-10 18-12 21-24 39-7 11-16 25-22 32-14 20-14 19-10 14-4 5-1 3 14 1 40 6 34 6 39 9 30 9 27 10 24 11 14 8 17 12 13 12 9 12 6 12 3 10 1 7v9l-3 12-8 16-8 10-13 12-15 10-14 8-25 12-34 12-24 7-33 8-36 7-45 6-38 4-45 3-37 1h-30l-38-1-57-4-64-8-29-5-44-10-27-8-26-9-23-10-19-10-12-8-16-13-9-10-8-13-4-12v-25l2-9 7-13 8-10 8-8 13-10 18-11 16-8 24-10 33-11 40-10 35-7 45-7 16-2h5l-13-19-13-18-12-17-22-32-28-42-14-23-13-22-8-13-6-11-20-39-14-30-12-31-8-25-6-25-3-21-1-21 2-32 5-29 6-24 9-25 7-16 8-16 10-18 8-12 10-14 11-13 18-20 8-7 9-9 14-11 17-12 18-11 16-8 17-8 24-9 25-7 25-5zm-8 30-27 4-29 7-27 9-16 7-20 11-18 12-19 14-12 11-8 7-11 11-18 22-10 15-10 17-11 21-8 20-7 23-5 22-3 21-1 12v27l3 22 6 26 12 35 11 26 15 31 12 22 10 19 8 13 10 17 15 24 22 33 12 17 14 20 12 17 13 18 13 17 8 11 24 32 13 17 14 18 12 15 10 13 14 18h3l8-11 13-16 56-72 16-21 10-14 14-19 10-14 14-20 13-19 10-15 14-22 26-42 16-28 14-26 8-15 16-36 9-23 8-26 5-23 2-14v-27l-3-27-4-22-7-27-10-26-12-24-13-21-10-14-9-11-11-12-13-13-11-9-18-14-22-14-18-10-24-10-26-8-22-5-19-3-13-1z" fill="#B06FBE"/>
            <path transform="translate(360,738)" d="m0 0h8l7 8 12 16 8 10 1 4-38 7-19 5-20 7-16 8-10 7-8 11-2 6v12l3 9 6 8 9 8 10 6 11 5 22 7 34 7 41 5 30 2h36l40-3 30-4 26-5 25-7 15-6 12-7 10-9 5-6 3-7v-14l-4-9-6-8-9-7-17-9-19-7-19-5-28-5-10-2 1-5 9-10 12-16 6-7 18 1 39 5 32 5 29 6 34 9 36 12 21 9 18 10 13 9 10 9 7 8 5 10 1 3v8l-4 10-7 9-8 8-12 9-20 11-25 11-35 12-34 9-29 6-41 6-47 5-36 3-20 1h-92l-44-3-47-5-38-5-36-7-32-8-37-12-24-10-16-8-11-6-12-9-10-10-6-10-3-10 1-10 6-10 7-8 14-11 13-8 15-8 27-11 25-8 38-10 28-6 46-7z" fill="#FEFFFE"/>
            <path transform="translate(456,196)" d="m0 0h23l19 3 21 7 15 8 14 10 12 11 7 7 10 14 9 17 6 17 4 19 1 7v22l-5 25-8 20-9 15-9 11-9 10-13 11-16 10-13 6-16 5-15 3-8 1h-18l-19-3-21-6-23-12-16-13-8-8-11-14-8-13-7-16-5-17-3-19v-21l2-14 5-18 9-19 10-15 9-10 10-10 13-10 16-9 15-6 16-4z" fill="#FEFFFE"/>
            <path transform="translate(460,166)" d="m0 0h15l20 2 17 4 16 6 21 10 14 10 10 9 8 7 11 12 10 15 10 18 8 21 4 16 3 22v19l-4 23-8 26-8 16-10 16-11 14-10 10-14 11-16 10-15 8-15 6-17 5-24 3h-16l-20-2-22-6-18-8-12-6-15-10-10-8-13-12-12-15-8-13-8-15-7-19-5-23-1-7v-32l3-18 4-15 8-19 8-15 9-13 8-10 13-13 14-11 14-9 19-9 21-7 17-3zm-4 30-19 3-17 5-17 8-12 8-13 11-13 13-10 15-8 15-5 14-4 16-1 9v21l3 19 6 20 8 17 9 13 12 14 7 7 13 10 23 12 21 6 19 3h18l19-3 15-4 16-7 15-9 10-8 12-11 13-16 9-16 7-18 5-25v-22l-4-23-5-15-9-19-9-13-11-12-7-7-13-10-18-10-16-6-18-4-8-1z" fill="#B06FBE"/>
            <path transform="translate(518,803)" d="m0 0 28 4 31 6 20 6 19 9 6 5-1 4-10 6-19 8-20 6-20 4-32 4-33 2h-37l-36-2-31-4-24-5-20-6-17-7-9-6-1-5 11-7 20-8 19-5 26-5 20-3h11l8 9 12 16 9 11 9 10 5 4 5 1 7-2 6-5 7-9 12-14 10-13 7-8z" fill="#FEFFFE"/>
            </svg>
            <Typography style={{ marginTop: 8, fontSize: "20px", fontWeight: 700 }}>ХАЯГ БАЙРШИЛ</Typography>
          </div>
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 20 }}>
        <Card
          title="Бидний тухай"
          style={{
            width: 1200,
            textAlign: "center",
            fontSize: 22,
            borderRadius: 0,
            fontStyle: "italic",
          }}
        >
          <Typography style={{ fontSize: 16, fontStyle: "italic" }}>
            "Мөрөөдлийн жигүүр боловсролын төв нь Англи хэл, математик, EQ буюу
            сэтгэлзүйн боловсрол, хүүхдийн хөгжлийн чиглэлээр сургалт,
            үйл ажиллагаагаа явуулдаг. Бид 2020 онд байгуулагдсан ба 5 жилийн
            хугацаанд 1 ажилтантай эхэлж, өдгөө 20 гаруй ажилтантай болж, Ховд
            аймгийн 530 гаруй суралцагчдад боловсролын үйлчилгээ үзүүлж байна."
          </Typography>
          <div style = {{backgroundColor: "#002333", paddingTop: 10, paddingBottom:20, marginTop:20, marginBottom: 20 }}>
            <Typography style={{ marginTop: 16, fontSize: 22, fontWeight:700, color: '#fff' }}>
              Эрхэм зорилго
            </Typography>
            <Typography style={{ fontSize: 16, fontStyle: "italic", color: '#fff' }}>
              Хүүхэд, залууст чанартай, сонирхолтой, үр дүнтэй боловсрол олгох
              замаар тэдний мэдлэг, чадварыг нэмэгдүүлж, ирээдүйд нь эерэг өөрчлөлт
              авчрах нь бидний эрхэм зорилго юм.
            </Typography>
          </div>
          <div>
            <Typography style={{ paddingTop: 16, paddingBottom: 16, fontSize: 22, fontWeight:700 }}>
              Сургалтын хөтөлбөрүүд 
            </Typography>
            <List
              bordered
              dataSource={training_programs}
              style={{borderRadius: 0}}
              renderItem={(item) => (
                <List.Item>
                  <span dangerouslySetInnerHTML={{ __html: item }} style={{fontSize: 16}}/>
                </List.Item>
              )}
            />
          </div>
          <Typography style={{ marginTop: 16, fontSize: 22, fontWeight:700 }}>
            Онцлог давуу талууд
          </Typography>
          <ul style={{ textAlign: "left", fontSize: 16, paddingLeft: 20 }}>
            {special_advantages.map((special_advantages, index) => (
              <li
                key={index}
                style={{
                  listStyle: "none",
                  position: "relative",
                  paddingLeft: 20,
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 12,
                    width: 8,
                    height: 8,
                    backgroundColor: "black",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                ></span>
                {special_advantages}
              </li>
            ))}
          </ul>
          <Typography style={{ marginTop: 16, fontSize: 22, fontWeight:700 }}>
            Байршил, холбоо барих
          </Typography>
          <Typography style={{ marginTop: 16, fontSize: 16}}>
          <p>📍 Хаяг: Ховд аймаг, Жаргалант сум, Жаргалан баг, Вансэмбэрүү байр </p><p>📞 Утас: 88066980   И-мэйл: Badamtsetseg.namsrai@gmail.com </p> <p>🌐 Вэбсайт: https//dreamwings.mn 📌 Facebook хуудас: https://www.facebook.com/DreamMagicWings  </p>
          <p>Манай сургалтын төвд тавтай морил! Танай хүүхдийн боловсрол, хөгжилд бид туслахдаа таатай байх болно.</p> 
          </Typography>
          </Card>
        </div>
        </Col>
      </Row>
    </>
  );
};

export default HomeContainer;