import React from "react";
import MainLayout from "src/Layouts/MainLayout";
import { Tabs, Avatar, Typography, Image, Row, Col, Card } from "antd";

const { TabPane } = Tabs;
const { Meta } = Card;
const { Title, Text, Paragraph } = Typography;

const Blog9: React.FC = () => (
  <MainLayout>
    <div
      style={{
        width: "100%",
        maxWidth: 900,
        margin: "0 auto",
        minHeight: "calc(100vh - 64px)",
        marginTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Avatar
            size={100}
            src="/images/profile/naraa.png"
            style={{ marginRight: "20px" }}
          />
          <Text
            style={{
              fontSize: "18px",
              color: "#6B6B6B",
              wordSpacing: "2px",
              fontWeight: 700,
            }}
          >
            EQ багш Э.Наранцэцэг
          </Text>
          <Title level={3}>Айдсыг мэдэрлээ гээд аймхай гэсэн үг биш</Title>
        </Col>
        <Col style = {{marginTop: 20, marginBottom: 20}} xs={24} sm={24} md={24} lg={10} xl={8}>
            <Image src="/images/blogs/blog9.png" />
        </Col>
        <Col style = {{marginTop: 20, marginBottom: 20}} xs={24} sm={24} md={24} lg={10} xl={16}>
            <Paragraph>Сэтгэл хөдлөл, мэдрэмжтэйгээ танилцаж байгаа хүүхдүүдийн хувьд энэ ертөнц тун сонирхолтой байдаг. Тэд хичээл дээр ам уралдан ярилцацгаадаг. Нэгэн өдөр би хүүхдүүдээсээ асуулаа. Айдсыг мэдрэх нь хэвийн үү? Та нар юу гэж бодож байна?</Paragraph>
        </Col>
        <Col style = {{marginTop: 20, marginBottom: 20}} xs={24} sm={24} md={24} lg={24} xl={24}>
            <ul>
                <li style={{listStyleType: "square"}}>Багшаа айх их буруу шүү дээ, Айхаар томчууд уурлаад байдаг. Том болчоод чи юунаасаа айгаад байдаг байнаа. Аймхай юм бэ? гээд байдаг </li>
                <li style={{listStyleType: "square"}}>Айх хэрэгтэй л байх даа. Гэхдээ энэ талаар бас сайн хэлж мэдэхгүй л байна. </li>
                <li style={{listStyleType: "square"}}>Би айсан үедээ ямар ч зориггүй болчихдог. </li>
                <li style={{listStyleType: "square"}}>Би олон хүний өмнө гарч ярихдаа маш их айж сандардаг. Гараад зугатаачихмаар л санагддаг шүү. </li>
                <li style={{listStyleType: "square"}}>Ээж, аав хоёр маргалдаж хэрэлдэхээр их айдаг. Хурдан эвлэрээд учраа олоосой гэж боддог. </li>
                <li style={{listStyleType: "square"}}>Би хайртай хүмүүсээ алдахаас айдаг. </li>
                <li style={{listStyleType: "square"}}>Ганцаардлыг мэдрэх вий гэхээс их айдаг. Найзгүй болчихвол маш муухай мэдрэмж. </li>
                <li style={{listStyleType: "square"}}>Ээж аавынхаа итгэлийг алдах вий гэхээс бас их айдаг даа. </li>
            </ul>
        </Col>
        <Col style = {{marginTop: 20, marginBottom: 20}} xs={24} sm={24} md={24} lg={24} xl={24}>
            <Paragraph>Айдас бол хэвийн сэтгэл хөдлөл. Яг л баярлаж, гомдож, уурладаг шиг л. Айдас биднийг аюултай осолтой зүйлсээс үргэлж хамгаалж, сэрэмжлүүлж байдаг. Жишээ нь, Ууланд авирч байхад хортой могой гараад ирвэл өөрийгөө хамгаалж зугатана шүү дээ. Айдас энэ үед: Зугатаарай, өөрийгөө аюулгүй газар аваачаарай гэдгийг сануулдаг сэтгэл хөдлөл юм. </Paragraph>
        </Col>
        <Col style = {{marginTop: 20, marginBottom: 20}} xs={24} sm={24} md={24} lg={12} xl={24}>
            <Paragraph>Нөгөө талаар айдас гэдэг биднийг түлхэгч хүч болдог. Жишээ нь, маргааш англи хэлний 20 шинэ үгийн шалгалттай шүү хүүхдүүдээ гээд багш хэллээ. Айдас энэ үед: Маргааш багш нь шинэ үгийг шалгалттай юм байна шүү, чи хичээлээ сайн бэлтгээд, шинэ үгсээ сайн цээжлэх хэрэгтэй шүү гэдгийг сануулдаг сэтгэл хөдлөл. </Paragraph>
            <Paragraph>Айдас биднийг хамгаалж байдаг, урагшаа түлхэж байдаг сэтгэл хөдлөл юм.</Paragraph>
        </Col>
      </Row>
    </div>
  </MainLayout>
);

export default Blog9;
