import React from "react";
import MainLayout from "src/Layouts/MainLayout";
import { Tabs, Avatar, Typography, Image, Row, Col, Card } from "antd";

const { TabPane } = Tabs;
const { Meta } = Card;
const { Title, Text, Paragraph } = Typography;

const Blog10: React.FC = () => (
  <MainLayout>
    <div
      style={{
        width: "100%",
        maxWidth: 900,
        margin: "0 auto",
        minHeight: "calc(100vh - 64px)",
        marginTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Avatar
            size={100}
            src="/images/profile/hulan.png"
            style={{ marginRight: "20px" }}
          />
          <Text
            style={{
              fontSize: "18px",
              color: "#6B6B6B",
              wordSpacing: "2px",
              fontWeight: 700,
            }}
          >
            Багш: Хулан
          </Text>
          <Title level={3}>Бид ямар үеийн сурагчдад хичээл зааж байна вэ?</Title>
          <Paragraph>
          Боловсролын салбар цаг үеийн өөрчлөлттэй уялдан шинэчлэгдэж, сурагчдын сурах хэв маяг, сонирхолд нийцсэн арга зүйг багш биднээс шаардаж байдаг. Өнөөдөр бид <b>Z үеийн сүүлч болон Альфа үеийн сурагчдад</b> хичээл зааж байна.
          </Paragraph>
        </Col>
        <Col style = {{marginTop: 20, marginBottom: 20}} xs={24} sm={24} md={24} lg={24} xl={24}>
            <Image src="/images/blogs/blog10.png" />
        </Col>
        <Col>
            <Paragraph>
                <b>Альфа үеийнхэн (2010 оноос хойш төрсөн хүүхдүүд)</b> бол төрөлхийн дижитал ур чадвартай, технологийн дэвшилтэт орчинд өссөн, мэдээллийг хурдан боловсруулж чаддаг онцлогтой хүүхдүүд юм. Эдгээр шинэ үеийн хүүхдүүдэд хичээл зааж буй багш өөрийн үед заагдаж байсан аргаараа буюу уламжлалт аргаар хичээл орох нь тэдний оршихуйг үгүйсгэсэн хэлбэр болох биз ээ
            </Paragraph>
        </Col>
        <Col style = {{marginTop: 20, marginBottom: 20}} xs={24} sm={24} md={8} lg={8} xl={8}>
            <Image src="/images/blogs/blog10-2.png" />
        </Col>
        <Col style = {{marginTop: 20, marginBottom: 20}} xs={24} sm={24} md={16} lg={16} xl={16}>
            <Paragraph>
                Өмнөх үеийнхний хувьд сурах үйл явц ихэвчлэн ном, самбар дээр суурилсан байхад өнөөгийн сурагчид технологийг байнга ашигладаг. Гар утас, таблет, ухаалаг самбар, онлайн платформууд тэдний амьдралын салшгүй нэг хэсэг юм. Тэд мэдээллийг текст хэлбэрээр унших бус, Youtube, Tiktok-ийн видео үзэх, Google дээр хайлт хийх зэргээр хүлээж авахыг илүүд үздэг.
            </Paragraph>
        </Col>
        <Col style = {{marginTop: 20, marginBottom: 20}} xs={24} sm={24} md={16} lg={16} xl={16}>
            <Paragraph>
                Шударга ёс, бие даасан байдал, хувь хүний үзэл бодлоо эрхэмлэн хамгаалдаг учир
                “МИНИЙ ҮЕД БОЛООД Л БАЙСАН ЮМ ДАГ. ЧИ Ч БАС ҮҮНИЙГ ХИЙ”
            </Paragraph>
            <Paragraph>
                хэмээн тулган шаардах нь Альфа үеийн сурагчдад хэзээ ч нөлөөлөхгүй. Тиймээс багш бид сурагчдаа илүү сайн ойлгож, тэдний онцлогт тохирсон арга зүйг ашиглах шаардлагатай. Тулган шаардахаас илүү <b>Яагаад?</b> гэдэг асуултын хариуг тэдэнд өгвөл ойлголцолд хүрэх магадлал илүү өндөр байгааг хичээл дээрээ анзаарч байна. 
            </Paragraph>
            <Title level={3}>Бид ирээдүйг бүтээж байна.</Title>
            <Paragraph>
            Бид ирээдүйг бүтээж байна. Өнөөдөр зөвхөн мэдлэг өгөх бус, ирээдүйн чадварлаг, бүтээлч иргэдийг бэлтгэх нь багш бүрийн үүрэг билээ. Тиймээс бид өнгөрсөн рүү биш ирээдүй рүү харж одоо байгаа сурагчдынхаа онцлогийг хүлээн зөвшөөрөх зайлшгүй шаардлагатай байна.
            </Paragraph>
        </Col>
      </Row>
    </div>
  </MainLayout>
);

export default Blog10;
