import React from "react";
import MainLayout from "src/Layouts/MainLayout";
import { Tabs, Avatar, Typography, Image, Row, Col, Card } from "antd";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const { TabPane } = Tabs;
const { Meta } = Card;
const { Title, Text, Paragraph } = Typography;

const BlogContainer: React.FC = () => {
  const navigate = useNavigate();

  const handleCardClick = (blog_url: string) => {
    navigate(blog_url);
  };

  return (
    <div>
      <Helmet>
        <title>Dreamwings Blogs</title>
        <meta property="og:title" content="Dreamwings Blogs" />
        <meta property="og:description" content="Dreamwings блогууд" />
        <meta property="og:image" content="/blog1" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <Row gutter={[24, 24]}>
        <Col>
          <Card
            className="dream-blog-list card-blog"
            onClick={() => handleCardClick("/blog1")}
          >
            <div style={{ width: "362px", alignItems: "center" }}>
              <img
                src="/images/blogs/say_it_loud.png"
                className="blog-index-list-img"
              />
              <div>
                <Meta
                  avatar={
                    <Avatar size={80} src="/images/profile/suvd-erdene.png" />
                  }
                  title="SAY IT LOUD!"
                  description="Англи хэлний багш: Сувд-Эрдэнэ"
                />
              </div>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            className="dream-blog-list card-blog"
            onClick={() => handleCardClick("/blog2")}
          >
            <div style={{ width: "362px", alignItems: "center" }}>
              <img
                src="/images/blogs/hulan_blog.png"
                className="blog-index-list-img"
              />
              <div>
                <Meta
                  avatar={
                    <Avatar size={80} src="/images/profile/soyol-erdene.png" />
                  }
                  title="Хүүхэд бүр өөр өөрийн гэсэн сурах арга барилтай"
                  description="Англи хэлний багш: Б.Соёл-Эрдэнэ"
                />
              </div>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            className="dream-blog-list card-blog"
            onClick={() => handleCardClick("/blog3")}
          >
            <div style={{ width: "362px", alignItems: "center" }}>
              <img
                src="/images/blogs/hulan_blog.png"
                className="blog-index-list-img"
              />
              <div>
                <Meta
                  avatar={<Avatar size={80} src="/images/profile/khulan.png" />}
                  title="Англи хэл сурах хялбар арга"
                  description="Англи хэлний багш: Хулан"
                />
              </div>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            className="dream-blog-list card-blog"
            onClick={() => handleCardClick("/blog4")}
          >
            <div style={{ width: "362px", alignItems: "center" }}>
              <img src="/images/blogs/eq.png" className="blog-index-list-img" />
              <div>
                <Meta
                  avatar={<Avatar size={80} src="/images/profile/naraa.png" />}
                  title="EQ чадвар хөгждөг"
                  description="EQ багш Э.Наранцэцэг"
                />
              </div>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            className="dream-blog-list card-blog"
            onClick={() => handleCardClick("/blog5")}
          >
            <div style={{ width: "362px", alignItems: "center" }}>
              <img
                src="/images/blogs/mate.png"
                className="blog-index-list-img"
              />
              <div>
                <Meta
                  avatar={
                    <Avatar size={80} src="/images/profile/oyunkhuu.png" />
                  }
                  title={
                    "Бодлого нь математик \n cургалтыг амьдралтай \n холбох гүүр болж өгдөг. "
                  }
                  description="Англи хэлний багш: Оюунхүү"
                />
              </div>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            className="dream-blog-list card-blog"
            onClick={() => handleCardClick("/blog6")}
          >
            <div style={{ width: "362px", alignItems: "center" }}>
              <img
                src="/images/blogs/why.jpg"
                className="blog-index-list-img"
              />
              <div>
                <Meta
                  avatar={
                    <Avatar size={80} src="/images/profile/oyuntungalag.png" />
                  }
                  title="Зөвхөн Англи үсэг битгий заагаарай."
                  description="Англи хэлний багш: Оюунтунгалаг"
                />
              </div>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            className="dream-blog-list card-blog"
            onClick={() => handleCardClick("/blog7")}
          >
            <div style={{ width: "362px", alignItems: "center" }}>
              <img
                src="/images/blogs/eq.png"
                className="blog-index-list-img"
              />
              <div>
                <Meta
                  avatar={<Avatar size={80} src="/images/profile/naraa.png" />}
                  title="Хүүхдүүдэд ч бас мэдрэмж бий..."
                  description="EQ багш Э.Наранцэцэг"
                />
              </div>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            className="dream-blog-list card-blog"
            onClick={() => handleCardClick("/blog8")}
          >
            <div style={{ width: "362px", alignItems: "center" }}>
              <img
                src="/images/blogs/hulan_blog.png"
                className="blog-index-list-img"
              />
              <div>
                <Meta
                  avatar={<Avatar size={80} src="/images/profile/dalaisuvd.png" />}
                  title="АНУ-н дунд сургуулиуд ямархуу байдаг вэ?"
                  description="Англи хэлний багш: Б.Далайсувд"
                />
              </div>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            className="dream-blog-list card-blog"
            onClick={() => handleCardClick("/blog9")}
          >
            <div style={{ width: "362px", alignItems: "center" }}>
              <img
                src="/images/blogs/eq.png"
                className="blog-index-list-img"
              />
              <div>
                <Meta
                  avatar={<Avatar size={80} src="/images/profile/naraa.png" />}
                  title="Айдсыг мэдэрлээ гээд аймхай гэсэн үг биш"
                  description="EQ багш Э.Наранцэцэг"
                />
              </div>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            className="dream-blog-list card-blog"
            onClick={() => handleCardClick("/blog10")}
          >
            <div style={{ width: "362px", alignItems: "center" }}>
              <img
                src="/images/blogs/hulan_blog.png"
                className="blog-index-list-img"
              />
              <div>
                <Meta
                  avatar={<Avatar size={80} src="/images/profile/hulan.png" />}
                  title="Бид ямар үеийн сурагчдад хичээл зааж байна вэ?"
                  description="Англи хэлний багш: Хулан"
                />
              </div>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            className="dream-blog-list card-blog"
            onClick={() => handleCardClick("/blog11")}
          >
            <div style={{ width: "362px", alignItems: "center" }}>
              <img
                src="/images/blogs/why.jpg"
                className="blog-index-list-img"
              />
              <div>
                <Meta
                  avatar={<Avatar size={80} src="/images/profile/tsogzolmaa.png" />}
                  title="Альфа үеийн хүүхдүүдийн онцлог ба тэдэнтэй хэрхэн ажиллах вэ?"
                  description="Англи хэлний багш: Цогзолмаа"
                />
              </div>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            className="dream-blog-list card-blog"
            onClick={() => handleCardClick("/blog12")}
          >
            <div style={{ width: "362px", alignItems: "center" }}>
              <img
                src="/images/blogs/why.jpg"
                className="blog-index-list-img"
              />
              <div>
                <Meta
                  avatar={<Avatar size={80} src="/images/profile/battsetseg.png" />}
                  title="Технологийн талаар хүүхдүүдтэйгээ ярилцаарай"
                  description="Багш: Батцэцэг"
                />
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default BlogContainer;
