import React from "react";
import MainLayout from "src/Layouts/MainLayout";
import { Tabs, Avatar, Typography, Image, Row, Col, Card } from "antd";

const { TabPane } = Tabs;
const { Meta } = Card;
const { Title, Text, Paragraph } = Typography;

const Blog7: React.FC = () => (
  <MainLayout>
    <div
      style={{
        width: "100%",
        maxWidth: 900,
        margin: "0 auto",
        minHeight: "calc(100vh - 64px)",
        marginTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Avatar
            size={100}
            src="/images/profile/naraa.png"
            style={{ marginRight: "20px" }}
          />
          <Text
            style={{
              fontSize: "18px",
              color: "#6B6B6B",
              wordSpacing: "2px",
              fontWeight: 700,
            }}
          >
            Багш: Наранцэцэг
          </Text>
          <Title level={3}>Хүүхдүүдэд ч бас мэдрэмж бий...</Title>
          <Paragraph>
            Сэтгэл хөдлөл, мэдрэмж гэдэг зүйлийг хүнээс холдуулах ямар ч
            боломжгүй. Яг л бидний эд, эрхтэн шиг биднээс салгах боломжгүй чухал
            зүйлс юм. Тэгэхээр EQ хичээл орж байх явцад манай хүүхдүүд олон
            зүйлсийг ил гаргаж ярьдаг. Жишээ татвал,
          </Paragraph>
          <Paragraph>
            <li>
              - Ээж аав хоорондоо хэрэлдэж, маргалдах үеийн мэдрэмж бол үнэхээр
              муухай байдаг, юм ч хэлж болохгүй, чимээгүй дуугаа хураагаад л
              сууж байдаг даа. Хурдан учраа олоод эвлэрээсэй л гэж их хүсдэг.
              Ээж, аав нь салаад явсан хүүхдүүдэд багшаа бүр их хэцүү байдаг
              байхдаа гэж хааяа боддог шүү.
            </li>
          </Paragraph>
          <Paragraph>
            <li>
              - Заримдаа гэрээ цэвэрлээд, дүү нараа сайн харсан ч гэрийнхэн нээх
              тоодоггүй. Сайн байна гэж урам өгчихвөл би бас хүн шүү дээ.
            </li>
          </Paragraph>
          <Paragraph>
            <li>
              - Би эртээд нэг найзтайгаа муудалцаад, тэр өдөр нь гунигтай их
              хэцүү өнгөрсөн. Тэгээд орой нь хичээлээ тараад гэртээ ортол ээж
              намайг юундаа урвагар царайлаад байдаг юм? Ямар хүн амьтан нас
              барцан байна уу? гэх үед үнэхээр хэцүү санагдаж байсан.
            </li>
          </Paragraph>
          <Paragraph>
            <li>
              Эдгээр кейсүүдийг уншихад танд ямар санагдаж байна? Хүүхдүүдэд ч
              гэсэн мэдрэмж, сэтгэл хөдлөл бий. Тэд томчууд бидний хэлж байгаа
              үг, харилцаа хандлагыг шууд мэдэрч, өөрсдийн ойлголтоороо шууд
              хүлээж авч, тархиндаа бодож байдаг. Мэдрэмж, сэтгэл хөдлөлийг нь
              байгаагаар нь хүлээн зөвшөөрөөгүй үед тэд “Би муу хүүхэд”, “Тэд
              надад хайргүй” юм байна гэсэн бодлыг өөртөө бий болгож эхэлдэг
              шүү. Тиймээс хүүхдийнхээ мэдрэмж бүрийг хүлээн зөвшөөрч, ойлгож
              ярилцдаг тэр хүн нь байгаарай.
            </li>
          </Paragraph>
        </Col>
      </Row>
    </div>
  </MainLayout>
);

export default Blog7;
