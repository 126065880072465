import React from "react";
import MainLayout from "src/Layouts/MainLayout";
import { Tabs, Avatar, Typography, Image, Row, Col, Card } from "antd";

const { TabPane } = Tabs;
const { Meta } = Card;
const { Title, Text, Paragraph } = Typography;

const Blog8: React.FC = () => (
  <MainLayout>
    <div
      style={{
        width: "100%",
        maxWidth: 900,
        margin: "0 auto",
        minHeight: "calc(100vh - 64px)",
        marginTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Avatar
            size={100}
            src="/images/profile/dalaisuvd.png"
            style={{ marginRight: "20px" }}
          />
          <Text
            style={{
              fontSize: "18px",
              color: "#6B6B6B",
              wordSpacing: "2px",
              fontWeight: 700,
            }}
          >
            Багш: Б.Далайсувд
          </Text>
          <Title level={3}>АНУ-н дунд сургуулиуд ямархуу байдаг вэ?</Title>
          <Paragraph>
            АНУ-н ерөнхий боловсролын дунд сургуулиуд мужуудаасаа хамаараад өөр өөр тогтолцоотой байдаг ч хувийн 
            сургууль болон улсын сургуулиуд “K-12”-боловсролын тогтолцоогоор явдаг.Энэхүү систем нь 12н жил 
            сурах буюу 6н насандаа элсээд 18тайдаа төгсдөг.
          </Paragraph>
          <Paragraph>
            <ul>
                <li>6-9р дунд анги</li>
                <li>1-5р бага анги</li>
                <li>6-12р ахлах гэсэн</li>
            </ul>
          </Paragraph>
          <Paragraph>
            <li>
                Хичээлийн жил 9н сар үргэлжилдэг зун өвөлдөө амардаг.АНУ-н улсын 
                сургуулиуд дүрэмт хувцас өмсөхийг шаарддаггүй 
                голдуу хувийн сургуулиуд дүрэмт хувцас өмсдөг.
            </li>
          </Paragraph>
          <Paragraph>
            <li>
                АНУ-д хувийн сургуулиуд байдаг ч цөөхөн суралцагчидтай байдаг.
                Ихэнх хүүхдүүд нь улсын сургуульдаа хамрагддаг.
                Сургуулиуд хичээлээс гадуурх төрөл бүрийн дугуйлан 
                секцдээ суралцагчдаа идэвхтэй хамруулахыг хичээдэг 
                бөгөөд хөгжлийн бэрхшээлтэй хүүхдүүд болон өөр бусад 
                шалтгаанаар сургууль завсардсан хүүхдүүдийг боловсрол 
                болон нийгэмд идэвхтэй татан оруулдагаараа дэлхийд дээгүүрт ордог.
            </li>
          </Paragraph>
          <Paragraph>
            <li>
            Дэлхийн өнцөг булан бүрт амьдарч байгаа АНУ-н иргэншилтэй хүүхдүүд 
            ‘’homeschooling’’буюу гэрээрээ боловсрол эзэмшиж элсэлтийн ерөнхий 
            шалгалт өгч коллеж,их сургуульд элсэх боломжтой байдгаараа дэлхийн 
            бусад орнуудаасаа давуу талтай байдаг.
            </li>
          </Paragraph>
        </Col>
        <Col style = {{marginTop: 20, marginBottom: 20}} xs={24} sm={24} md={24} lg={24} xl={24}>
            <Image src="/images/blogs/blog8.png" />
        </Col>
      </Row>
    </div>
  </MainLayout>
);

export default Blog8;
