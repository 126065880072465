import React from "react";
import MainLayout from "src/Layouts/MainLayout";
import { Tabs, Avatar, Typography, Image, Row, Col, Card } from "antd";

const { TabPane } = Tabs;
const { Meta } = Card;
const { Title, Text, Paragraph } = Typography;

const Blog11: React.FC = () => (
  <MainLayout>
    <div
      style={{
        width: "100%",
        maxWidth: 900,
        margin: "0 auto",
        minHeight: "calc(100vh - 64px)",
        marginTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Avatar
            size={100}
            src="/images/profile/tsogzolmaa.png"
            style={{ marginRight: "20px" }}
          />
          <Text
            style={{
              fontSize: "18px",
              color: "#6B6B6B",
              wordSpacing: "2px",
              fontWeight: 700,
            }}
          >
            Багш: Цогзолмаа
          </Text>
          <Title level={3}>Альфа үеийн хүүхдүүдийн онцлог ба тэдэнтэй хэрхэн ажиллах вэ? </Title>
          <div>
            <Image src="/images/blogs/blog11.png" />
          </div>
          <Paragraph>
            Өнөөгийн багш нар нийгэмд маш ихээр шүүмжлүүлж ялангуяа сошиал ертөнцөөр янз бүрээр яригдах болсон энэ цаг үед бид юуг анхаарах вэ?гэдэг асуудал багш бидний өмнө тулгараад байна, энэ сэдвээр өөрийнхөө санаа бодлоо хуваалцъя гэж бодлоо.    Өнөөгийн багш нар нийгэмд маш ихээр шүүмжлүүлж ялангуяа сошиал ертөнцөөр янз бүрээр яригдах болсон энэ цаг үед бид юуг анхаарах вэ?гэдэг асуудал багш бидний өмнө тулгараад байна, энэ сэдвээр өөрийнхөө санаа бодлоо хуваалцъя гэж бодлоо.
          </Paragraph>
          <Paragraph>
            Нэгдүгээрт цаг үеэсээ түрүүлж алхдаггүй юмаа гэхэд хөл нийлж алхах. Энэ нь багш хүний хувьд шинэ үеийн хүүхдүүдээ таньж мэдэх, зөвхөн хичээлээ заагаад гараад явах биш тэдний эрэлт хэрэгцээ, сонирхол, тоглодог тоглоом,зан ааш, харилцаа хандлага, гээд бүгдийг нь нэг бүрчлэн таньж мэдэх, ажиглах, судлах нь маш чухал. 
          </Paragraph>
          <Paragraph>   
            Ялангуяа өнөөгийн багш бид Альфа болон Зи үеийн хүүхдүүдтэй ажиллаж байгаа нь биднээс ажил мэргэжлийн төдийгүй харилцааны ур чадварыг илүү ихээр шаардаж байна.Тэгвэл шинэ үеийн хүүхдүүд биднээс ямар ялгаатай, онцлог давуу талтай вэ?Тэдэнд ямар мэдээллийг хэрхэн ,ямар арга замаар  хүргэх вэ?гэдэг биднээс хамааралтай. Мэдээж тэд онцлог, бусдаас өөр гээд тэд бүгдийг өөрсдөө танин мэдэж, өөрсдөө бүтээж, өөрсдөө шууд л амжилтанд хүрчихэж чадахгүй, тэдэнд томчууд, ялангуяа багш бидний дэмжлэг, туслалцаа зайлшгүй шаардлагатай юм байна гэдгийг нь ойлгуулах, мэдрүүлэх, харуулах нь бидний үүрэг.
          </Paragraph>
          <Paragraph>
            Дэлхий даяар технологийн дэвшил маш ихээр хувьсан өөрчлөгдөж байгаа өнөөгийн нийгэмд өсч байгаа тэдний суралцах хэв маяг, зан төлөв өмнөх үеүдээс ялгаатай.Тэгвэл тэд ямар ялгаатай вэ? Тэдэнтэй хэрхэн ажиллах вэ?
          </Paragraph>
          <Paragraph>
            <li>
                <b>Насны ангилалаар хандлагыг ялгах: 4-6 нас</b>
            </li>
          </Paragraph>
          <Paragraph>
            <b>1.</b> Тоглоомоор дамжуулан сургах- дүрд тоглох, хөдөлгөөнт тоглоом, зураг, хөгжмөөр дамжуулан сургах.
          </Paragraph>
          <Paragraph>
            <b>2.</b> Анхаарлыг богино хугацаанд барих- 10-15 минут тутамд үйл ажиллагаагаа сольж шинэчлэх
          </Paragraph>
          <Paragraph>
            <b>3.</b> Магтаал урамшууллыг харамгүй өгөх – ‘’Чи чадлаа!’’ ‘’Гайхалтай зурсан байна’’гэх мэт эерэг үгсийг тогтмол ашиглах
          </Paragraph>
          <Paragraph>
            <li>
                <b>7-9 нас: </b>
            </li>
          </Paragraph>
          <Paragraph>
            <b>1.</b> Бага багаар хариуцлага өгч сургах – жижиг төслүүд, багийн ажил
          </Paragraph>
          <Paragraph>
            <b>2.</b> Асуулт асуух бодох чадварыг хөгжүүлэх –‘’Яагаад гэж бодож байна’’ яаж шийдэх вэ’’
          </Paragraph>
          <Paragraph>
            <b>3.</b> Өрсөлдөөнөөс илүү хамтран ажиллах чадварыг дэмжих
          </Paragraph>
          <Paragraph>
            <li>
                <b>10-12 нас:</b>
            </li>
          </Paragraph>
          <Paragraph>
            <b>1.</b> Сэтгэн бодох,шүүмжлэх чадвар хөгжиж эхэлдэг–өөрийн санааг илэрхийлэх боломж өг.
          </Paragraph>
          <Paragraph>
            <b>2.</b> Жижиг танилцуулга,багаар төслийн ажил хийлгэх.
          </Paragraph>
          <Paragraph>
            <b>3.</b> Тэднийг шийдвэр гаргахад оролцуулах-‘’Ямар тоглоом тоглох вэ’’  ’’Ямар сэдвээр      ажиллах вэ ’’гэх мэт 
          </Paragraph>
          <Paragraph>
            Энэ бүгдээс харахад багшийн ажил бол ердөө мэдрэмж, тухайн нөхцөл байдалд хүүхдүүдээ мэдрэх тэдэнд сэтгэлээсээ хандах, тэднийг сонсох энгийн л нэгэн хүний хандлага. Мэдээж шинэ үеийнхэний нийтлэг шинжүүдээс гадна тэд өөр өөрийн өссөн орчин, зан харилцаа,хандлага гээд бүгд өөр өөр ертөнц. Тэднийг таньж мэдэрч тэр бүгдэд тохирох түлхүүрийг олж тэдний ертөнцийн хаалгыг нээх нь л багшийн ур чадвар. Хүүхэд хэн өөрт нь хэрхэн сэтгэлээсээ хандаж байгааг,хайрлаж байгааг, зэмлэхэд хүртэл хэний төлөө яагаад гэдгийг ойлгодог,тэд бол агуу, гэгээлэг, хүчтэй бөгөөд маш эмзэг. Тэднийг хэрхэн аль болох өөрөөр нь ухааруулж, өөрөөр нь өөрийг нь таниулах, дуртай сонирхолтой зүйлээр нь дамжуулж өөрийнхөө хичээлийг хүргэх нь багшийн арга зүй.
          </Paragraph>
          <Paragraph>
            Тэд хэзээ ч хүнээр загнуулж, олон арван гэрийн даалгаварт дарагдаж, заавал унш гэсэн хэдэн номыг уншаад суухгүй ээ, учир нь тэд бие даан суралцах, өөрийн сонирхдог зүйлээ туршлага хайлтын үр дүнд мэдэж авах хүсэлтэй.Төн тэд сэтгэл хөдлөлийн оюун ухаан буюу EQ-гээ маш сайн хөгжүүлж, аливааг өөр арга барилаар суралцан, хүлээж авах болно,энэ нь юу гэсэн үг вэ? гэвэл тэд нэгэн хэвийн зүйлд баригдахаасаа өнгөрсөн гэсэн үг юм. Мөн тэдний анхаарал төвлөрөл богино байдаг тул хичээлүүдийг хөдөлгөөнтэй, интерактив, бяцхан сорилттой, богино хугацаанд мэдээллийг багтаан хичээлээ төлөвлөх нь тохиромжтой.
          </Paragraph>
          <Paragraph>
          Эцэст нь хэлэхэд багшийг хүндлэх хүндлэл тэр хүүхдэд байхгүй л бол ямар ч үр дүн хүлээгээд хэрэггүй, гэхдээ хүндлэл хүлээж таалагдах нь багш сурагчийн ёс зүйн харилцааны хэм хэмжээнд байх.  Иймд багш өөрийнхөө мэдлэг, ур чадвар, арга зүйгээ өдөр тутамдаа хөгжүүлж, мэргэжилдээ эзэн нь байж, сурагчиддаа хүндлэгдэх нь бидний хичээл үр дүнтэй болох нэг алхам. Багш бидний хамгийн том магтаал шагнал бол, эцэг эхчүүд, хүүхдүүдийн үнэлгээ. 
          </Paragraph>
        </Col>
      </Row>
    </div>
  </MainLayout>
);

export default Blog11;
