import React from "react";
import { Col, Typography, Row, Card, Avatar } from "antd";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;
const { Meta } = Card;

const data = [
  {
    id: 1,
    title: "Жишээ ярья подкаст ДУГААР 1. Ангийн дүрмээр аврагдсан нь",
    description:
      "МӨРӨӨДЛИЙН ЖИГҮҮР боловсролын төвөөс эрхлэн гаргадаг 7 хоног тутмын подкаст. ",
    image_src: "/images/podcast/podcast1.png",
  },
  {
    id: 2,
    title:
      "Жишээ ярья подкаст ДУГААР 2. Багшийн сэтгэл хөдлөл хүүхдэд нөлөөлөх нь",
    description:
      "МӨРӨӨДЛИЙН ЖИГҮҮР боловсролын төвөөс эрхлэн гаргадаг 7 хоног тутмын подкаст. ",
    image_src: "/images/podcast/podcast2.png",
  },
  {
    id: 3,
    title: "Жишээ ярья подкаст ДУГААР 3: Алдсан ч Айхгүй",
    description:
      "МӨРӨӨДЛИЙН ЖИГҮҮР боловсролын төвөөс эрхлэн гаргадаг 7 хоног тутмын подкаст. ",
    image_src: "/images/podcast/podcast3.png",
  },
  {
    id: 4,
    title: "Жишээ ярья подкаст ДУГААР 4. Тархийг нь ХОРДУУЛАХГҮЙГЭЭР сургая",
    description:
      "МӨРӨӨДЛИЙН ЖИГҮҮР боловсролын төвөөс эрхлэн гаргадаг 7 хоног тутмын подкаст. ",
    image_src: "/images/podcast/podcast4.png",
  },
  {
    id: 5,
    title: "Жишээ ярья подкаст ДУГААР 5. Хүүхдийнхээ хоол, боловсрол хоёрт АНХААР",
    description:
      "МӨРӨӨДЛИЙН ЖИГҮҮР боловсролын төвөөс эрхлэн гаргадаг 7 хоног тутмын подкаст. ",
    image_src: "/images/podcast/podcast5.png",
  },
  {
    id: 6,
    title: "Жишээ ярья подкаст ДУГААР 6. Ангийн дүрэм цаанаа УЧИРТАЙ л байж!",
    description:
      "МӨРӨӨДЛИЙН ЖИГҮҮР боловсролын төвөөс эрхлэн гаргадаг 7 хоног тутмын подкаст. ",
    image_src: "/images/podcast/podcast6.png",
  },
  {
    id: 7,
    title: "Жишээ ярья подкаст ДУГААР 7. Хүүхдүүдийн хайрыг татдаг миний НУУЦ АРГУУД",
    description:
      "МӨРӨӨДЛИЙН ЖИГҮҮР боловсролын төвөөс эрхлэн гаргадаг 7 хоног тутмын подкаст. ",
    image_src: "/images/podcast/podcast7.png",
  },
  {
    id: 8,
    title: "Жишээ ярья подкаст ДУГААР 8",
    description:
      "МӨРӨӨДЛИЙН ЖИГҮҮР боловсролын төвөөс эрхлэн гаргадаг 7 хоног тутмын подкаст. ",
    image_src: "/images/podcast/podcast8.png",
  },
  {
    id: 9,
    title: "Жишээ ярья подкаст ДУГААР 9",
    description:
      "МӨРӨӨДЛИЙН ЖИГҮҮР боловсролын төвөөс эрхлэн гаргадаг 7 хоног тутмын подкаст. ",
    image_src: "/images/podcast/podcast9.png",
  },
  {
    id: 10,
    title: "Жишээ ярья подкаст ДУГААР 10",
    description:
      "МӨРӨӨДЛИЙН ЖИГҮҮР боловсролын төвөөс эрхлэн гаргадаг 7 хоног тутмын подкаст. ",
    image_src: "/images/podcast/podcast9.png",
  },
];

const PodcastContainer: React.FC = () => {
  const navigate = useNavigate();

  const handleCardClick = (id: number) => {
    navigate(`/podcast/${id}`);
  };

  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col>
          <Title style={{ fontSize: 24 }}>Цуврал подкастууд</Title>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        {data.map((item, index) => (
          <Col xs={24} sm={24} md={8} lg={8} xl={8} span={8} key={index}>
            <Card
              hoverable
              style={{ height: 345, marginBottom: 20 }}
              onClick={() => handleCardClick(item.id)}
              cover={<img src={item.image_src} />}
            >
              <Meta title={item.title} description={item.description} />
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default PodcastContainer;
