import React from "react";
import { Col, Typography, Row, Space } from "antd";
import MainLayout from "src/Layouts/MainLayout";
import PodcastContainer from "src/Containers/Podcast";

const HomePage: React.FC = () => {
  return (
    <MainLayout>
      <div
        style={{
          width: "100%",
          maxWidth: 1200,
          margin: "0 auto",
          minHeight: "calc(100vh - 64px)",
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <PodcastContainer />
          </Col>
        </Row>
      </div>
    </MainLayout>
  );
};

export default HomePage;
