import React from "react";
import MainLayout from "src/Layouts/MainLayout";
import { Tabs, Avatar, Typography, Image, Row, Col, Card } from "antd";
import BlogContainer from "src/Containers/Blog";

const { TabPane } = Tabs;
const { Meta } = Card;
const { Title, Text, Paragraph } = Typography;

const BlogPage: React.FC = () => (
  <MainLayout>
    <div
      style={{
        width: "100%",
        maxWidth: 1500,
        margin: "0 auto",
        minHeight: "calc(100vh - 64px)",
      }}
    >
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} span={24}>
          <BlogContainer />
        </Col>
      </Row>
    </div>
  </MainLayout>
);

export default BlogPage;
