import { Routes, Route } from "react-router-dom";
import HomePage from "./Pages/Home";
import PodcastPage from "./Pages/Podcast";
import PodcastDetail from "./Pages/Podcast/Detail";
import LessonPage from "./Pages/Lesson/index";
import LessonDetail from "./Pages/Lesson/Detail";
import Blog from "./Pages/Blog/index";
import Blog1 from "./Pages/Blog/Blog1";
import Blog2 from "./Pages/Blog/Blog2";
import Blog3 from "./Pages/Blog/Blog3";
import Blog4 from "./Pages/Blog/Blog4";
import Blog5 from "./Pages/Blog/Blog5";
import Blog6 from "./Pages/Blog/Blog6";
import Blog7 from "./Pages/Blog/Blog7";
import Blog8 from "./Pages/Blog/Blog8";
import Blog9 from "./Pages/Blog/Blog9";
import Blog10 from "./Pages/Blog/Blog10";
import Blog11 from "./Pages/Blog/Blog11";
import Blog12 from "./Pages/Blog/Blog12";
import DownloadFilePage from "./Pages/DownloadFile";
import DownloadFileDetail from "./Pages/DownloadFile/DownloadFileDetail";
import "./App.css";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/podcast" element={<PodcastPage />} />
      <Route path="/blogs" element={<Blog />} />
      <Route path="/podcast/:id" element={<PodcastDetail />} />
      <Route path="/lesson" element={<LessonPage/>} />
      <Route path="/lesson/:url" element={<LessonDetail/>} />
      <Route path="/download_file" element={<DownloadFilePage />} />
      <Route path="/download_file/:id" element={<DownloadFileDetail />} />
      <Route path="/blog1" element={<Blog1 />} />
      <Route path="/blog2" element={<Blog2 />} />
      <Route path="/blog3" element={<Blog3 />} />
      <Route path="/blog4" element={<Blog4 />} />
      <Route path="/blog5" element={<Blog5 />} />
      <Route path="/blog6" element={<Blog6 />} />
      <Route path="/blog7" element={<Blog7 />} />
      <Route path="/blog8" element={<Blog8 />} />
      <Route path="/blog9" element={<Blog9 />} />
      <Route path="/blog10" element={<Blog10 />} />
      <Route path="/blog11" element={<Blog11 />} />
      <Route path="/blog12" element={<Blog12 />} />
    </Routes>
  );
}

export default App;
