import React from "react";
import { Col, Typography, Row, Card, Image, List, Button } from "antd";

const { Paragraph } = Typography;

const FileContainer: React.FC = () => {
  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col>
          <div className= "dreamwings-books" style = {{maxWidth: 800, margin: "0 auto", paddingLeft: 50, paddingRight: 50, paddingTop: 50}}>
            <div>
              <Image
                src="/images/books/english-alphabet.png"
                alt="English Alphabet Book"
                width={200}
              />
            </div>

            <Paragraph style={{paddingTop: 20, paddingBottom: 20}}>
              Англи хэл сурч байхад жижиг, том үсэг нүүр дүүрэн хуулж бичээд, үг цээжлээд л байдаг. Энэ нь хүүхдүүдэд уйтгартай байхаас гадна хуудас хуудас нь үсэг бичихдээ Англи хэлэнд улам дургүй болсоор байдаг. Шинээр суралцаж байгаа хүүхдүүдэд үсэг заахаас гадна түгээмэл АВИА-г нь мөн зааж өгснөөр нь хүүхдүүд хурдан уншиж сурахад дөхөм болдог. Жишээлбэл A үсэг 4 авиатай. Apple, baby, father, around гэх үгнүүдэд яаж дуудлагатай байгааг зааж өгнө. Мөн бүтэн үг өгүүлбэрээр англи хэлийг цээжлүүлэхэд туслах төрөл бүрийн хэлбэржих, хөгжөөнт шүлэг, аялгууг, асуулт хариулт, хөдөлгөөнт дасгалуудтай учир хүүхдүүд англи хэлийг судалж эхлээд хэдхэн хоногийн дараагаас шууд хэрэглээд эхэлдэг.
            </Paragraph>
            <Paragraph>
              <p>Тохиромжтой нас: 6+ </p>
              <p>Худалдаанд гарсан он: 2024-10-17  </p>
              <p>Формат: Зөөлөн хавтастай </p>
              <p>Нүүрний тоо: 96 нүүр </p>
              <p>Бүтээгдсэн хэл: Англи хэл </p>
              <p>Жин: 260 гр </p>
              <p>Хэмжээс : 275 x 210 x 7 мм</p>
              <p><b>ISBN-13:</b> 978-9919-9749-2-3</p>
            </Paragraph>
          </div>
        </Col>
        <Col>
          <div className= "dreamwings-books" style = {{maxWidth: 800, margin: "0 auto", paddingLeft: 50, paddingRight: 50, paddingTop: 50}}>
            <div>
              <Image
                src="/images/books/english-multi-letters.png"
                alt="English Alphabet Book"
                width={200}
              />
            </div>

            <Paragraph style={{paddingTop: 20, paddingBottom: 20}}>
            Англи хэлийг анхлан суралцаж байгаа хүүхдүүдэд үсгийн нийлэмжүүдийг авиа дуудлагатай нь зөв заах, цаашид бүтэн, үг өгүүлбэрээр уншуулж сургах, 15 төрлийн асуулт хариултаар дамжуулан Англи хэлний харилцан ярианд бэлтгэх зорилготой юм. 
            Энэ арга зүйгээр хүүхдүүдэд Англи хэлний суурийг маш хурдан цэгцтэй зөв тавьж маш хурдан англи хэлэнд дурлуулдаг. 
            </Paragraph>
            <Paragraph>
              <p><b>ISBN-13:</b> 978-9919-9749-4-7</p>
              <p>Тохиромжтой нас: 6+ </p>
              <p>Худалдаанд гарсан он:  2024-11-21  </p>
              <p>Формат: Зөөлөн хавтастай </p>
              <p>Нүүрний тоо: 96 нүүр </p>
              <p>Бүтээгдсэн хэл: Англи хэл </p>
              <p>Жин: 260 гр </p>
              <p>Хэмжээс : 265 x 205 x 5 мм</p>
            </Paragraph>
          </div>
        </Col>
        <Col>
          <div className= "dreamwings-books" style = {{maxWidth: 800, margin: "0 auto", paddingLeft: 50, paddingRight: 50, paddingTop: 50}}>
            <div>
              <Image
                src="/images/books/bodlogo-amarhan.png"
                alt="English Alphabet Book"
                width={200}
              />
            </div>

            <Paragraph style={{paddingTop: 20, paddingBottom: 20}}>
            <p>2-р ангийн хүүхдүүдийн тухайд хийсвэр зүйлээс илүүтэй тэдний амьдралд байдаг, мэддэг бодит зүйлсээр нь дамжуулж шинэ зүйлийг сургах нь илүү үр дүнтэй байдаг. Энэ ном нь 400 орчим бодлоготой ба</p>
            <p>🎯 Хүүхдийн амьдралд ойр байдаг, тэдний сайн мэддэг зүйлсээс санаа авч бодлогоо зохиосон бөгөөд энэ номыг ашиглах үед хүүхдүүд маш хөөрхөн дуу шуутай, цоглог хичээлдээ оролцдог.</p>
            <p>🎯 Нэг хуудас дээр хоорондоо уялдаа холбоотой 3-5 бодлоготой учир хүүхдүүд үе шаттай тоглоом тоглож байгаа юм шиг л шуурхай бодчихдог. Залхдаггүй. Үүний цаад зорилго нь хүүхдийн тархины ажилладаг зарчим дээр тулгуурлан зохиосон ба хүний тархи хоорондоо уялдаа холбоотой зүйлсийг маш хурдан тогтоож авдаг.</p>
            <p>🎯Бодлого бүрийг номон дээрээ шууд бодоход зориулсан хэсэгтэй тул нь хүүхдүүдэд сонирхолтой байдаг.</p>
            <p>Хэрэглэж байгаа хүүхдүүд нь номон дээрээ бодох гоё байна, өгүүлбэрүүд нь гоё байна, өнгө нь гоё байна гэж хэлдэг.</p>
            </Paragraph>
            <Paragraph>
              <p><b>ISBN-13:</b> 978-9919-9749-3-0</p>
              <p>Тохиромжтой нас : 6+</p>
              <p>Худалдаанд гарсан огноо : 2024-10-17</p>
              <p>Формат : Зөөлөн хавтастай</p>
              <p>Нүүрний тоо : 96 нүүр</p>
              <p>Бүтээлийн хэл : Монгол</p>
              <p>Жин : 190 гр</p>
              <p>Хэмжээс : 250 x 175 x 7 мм</p>
            </Paragraph>
          </div>
        </Col>
        <Col>
          <div className= "dreamwings-books" style = {{maxWidth: 800, margin: "0 auto", paddingLeft: 50, paddingRight: 50, paddingTop: 50}}>
            <div>
              <Image
                src="/images/books/zurah-temuulel.png"
                width={200}
              />
            </div>
            <Paragraph style={{paddingTop: 20, paddingBottom: 20}}>
            Хүүхэд бүрт зурах тэмүүллийг бий болгох зорилготой, хялбар аргаар, үе шаттай зурах дарааллыг багтаасан, маш ойгомжтой алхам , алхамаар харуулсан учир хүүхэд бие дааж зурж сурахад ач холбогдолтой, хүүхдийн сонирхолд нийцсэн зурах дасгал ажлын хуудастай.
Анти хэмээх бяцхан ажилч шоргоолжтой хамт төрөл бүрийн зургийг янз бүрийн дүрс ашиглан зурэ, төсөөлөн ярилцах явцдаа хөгжилтэйгээр зурж сурах болно.
Бүх дасгал ажлууд нь үнээхр хөгжилтэй бөгөөд хүүхдийн эрүүл мэндэд тохирсон маш сайн чанарын цаас, будгаар хэвлэсэн.
            </Paragraph>
            <Paragraph>
              <p><b>ISBN-13:</b> 978-9919-25-020-1</p>
              <p>Худалдаанд гарсан огноо : 2021-08-17</p>
              <p>Формат : Зөөлөн хавтастай</p>
              <p>Нүүрний тоо : 52 нүүр</p>
              <p>Бүтээлийн хэл : Монгол</p>
              <p>Жин : 240 гр</p>
              <p>Хэмжээс : 290 x 210 x 5 мм</p>
            </Paragraph>
            </div>
        </Col>
        <Col>
          <div className= "dreamwings-books" style = {{maxWidth: 800, margin: "0 auto", paddingLeft: 50, paddingRight: 50, paddingTop: 50}}>
            <div>
              <Image
                src="/images/books/bichih-temuulel.png"
                alt="English Alphabet Book"
                width={200}
              />
            </div>
            <Paragraph style={{paddingTop: 20, paddingBottom: 20}}>
              Тус ном нь сургуулийн өмнөх насны болон бэлтгэл бүлгийн хүүхдүүдэд зориулсан ба Монгол хэлний Цагаан толгойн 35 үсгийг үндсэн 10 төрлийн зурлагаар бичдэг. Тэдгээр зурлагуудыг зөв бичиж сурах, гар зүгшрүүлэх, хүүхдийн сонирхолд нийцсэн хөгжилтэй, олон төлийн дасгал ажлуудыг багтаасан ном юм.
            </Paragraph>
            <Paragraph>
              <li style={{listStyleType: 'square' }}>Хүүхэд хүрч мэдэрсэн зүйлээ хэзээ ч мартдаггүй тул том, товгор, хуруугаар мэдэрч бичдэг хэсэгтэй.</li>
              <li style={{listStyleType: 'square' }}>Олон жижиг зүйл бичихэд хүүхдийн гар маш хурдан ядарч, чилдэг тул том зурлагаас эхлэн жижиг зурлага хүртэл бичиж болно.</li>
              <li style={{listStyleType: 'square' }}>Анти хэмээх бяцхан, ажилч шоргоолжтой хамт төрөл бүрийн хөгжилтэй зураг, зурлага зурж, тоо бодож, төсөөлөн харилцан ярих явцдаа уйдахгүйгээр сурах боломжтой.</li>
              <li style={{listStyleType: 'square' }}>Бага насны хүүхдүүдийн өөрийгөө илэрхийлэх, бусад харилцах чадвар төдийлэн хөгжөөгүй байдаг тул энэхүү номны дасгал ажлыг хийх явцад харилцааны чадваруудыг эзэмших боломжтой.</li>
              <li style={{listStyleType: 'square' }}>Бага насны хүүхдийн анхаарал маш хурдан сарнидаг тул #Тэмүүлэл номны дэлгэмэл хуудас дээр үндсэн дасгал ажлыг 4 төрлөөр ашиглах, анхаарлыг нь өөр бусад өнгө будаг, зурагт сарниулахгүй байх давуу талтай.</li>
              <li style={{listStyleType: 'square' }}>Бүх дасгал ажлууд нь үнэхээр хөгжилтэй, цаас өнгө будаг нь бага насны хүүхдэд тохирох маш чанартай ном юм.</li>
            </Paragraph>
            <Paragraph>
              <p><b>ISBN-13:</b> 978-9919-25-020-1</p>
              <p>Тохиромжтой нас : 4-6</p>
              <p>Худалдаанд гарсан огноо : 2021-08-17</p>
              <p>Формат: Зөөлөн хавтастай </p>
              <p>Нүүрний тоо : 242</p>
              <p>Бүтээлийн хэл : Монгол</p>
              <p>Жин : 242 гр</p>
              <p>Хэмжээс : 207 x 293 x 3 мм</p>
            </Paragraph>
            </div>
        </Col>
        <Col>
          <div className= "dreamwings-books" style = {{maxWidth: 800, margin: "0 auto", paddingLeft: 50, paddingRight: 50, paddingTop: 50}}>
            <div>
              <Image
                src="/images/books/bodoh-temuulel.png"
                alt="English Alphabet Book"
                width={200}
              />
            </div>
            <Paragraph style={{paddingTop: 20, paddingBottom: 20}}>
              <p>Тус ном нь сургуулийн өмнөх насны болон бэлтгэл бүлгийн хүүхдүүдэд зориулсан 0-9 хүртэлх цифрийн зөв бичих зурлага болон дасгал ажлуудын хамт багтаасан ба дараах олон төлийн давуу талуудтай.</p>
              <p> - Хүүхэд хүрч мэдэрсэн зүйлээ хэзээ ч мартдаггүй тул том, товгор, хуруугаар мэдэрч бичдэг хэсэгтэй.</p>
              <p> - Олон жижиг зүйл бичихэд хүүхдийн гар маш хурдан ядарч, чилдэг тул том зурлагаас эхлэн жижиг зурлага хүртэл бичиж болно. </p>
              <p> - Анти хэмээх бяцхан, ажилч шоргоолжтой хамт төрөл бүрийн хөгжилтэй зураг, зурлага зурж, тоо бодож, төсөөлөн харилцан ярих явцдаа уйдахгүйгээр сурах боломжтой. </p>
              <p> - Бага насны хүүхдүүдийн өөрийгөө илэрхийлэх, бусад харилцах чадвар төдийлэн хөгжөөгүй байдаг тул энэхүү номны дасгал ажлыг хийх явцад харилцааны чадваруудыг эзэмших боломжтой. </p>
              <p> - Бага насны хүүхдийн анхаарал маш хурдан сарнидаг тул #Тэмүүлэл номны дэлгэмэл хуудас дээр үндсэн дасгал ажлыг 4 төрлөөр ашиглах, анхаарлыг нь өөр бусад өнгө будаг, зурагт сарниулахгүй байх давуу талтай. </p>
              <p> - Бүх дасгал ажлууд нь үнэхээр хөгжилтэй, цаас өнгө будаг нь бага насны хүүхдэд тохирох маш чанартай ном юм. </p>
            </Paragraph>
            <Paragraph>
              <p><b>ISBN-13:</b> 978-9919-25-020-1</p>
              <p>Тохиромжтой нас : 4-6</p>
              <p>Худалдаанд гарсан огноо : 2021-08-17</p>
              <p>Формат: Зөөлөн хавтастай </p>
              <p>Нүүрний тоо : 242 нүүр</p>
              <p>Бүтээлийн хэл : Монгол</p>
              <p>Жин : 242 гр</p>
              <p>Хэмжээс : 207 x 293 x 3 мм</p>
            </Paragraph>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FileContainer;
