import React from "react";
import { Row, Col, Typography, Space, Avatar } from "antd";
import MainLayout from "src/Layouts/MainLayout";
import { useParams } from "react-router-dom";

const { Title, Paragraph, Text, Link } = Typography;

interface DataItem {
  id: number;
  title: string;
  description: string;
  video_url: string;
  teacher1_name: string;
  teacher2_name: string;
  teacher1_img_url: string;
  teacher2_img_url: string;
}

const data: DataItem[] = [
  {
    id: 1,
    title: "Жишээ ярья подкаст ДУГААР 1. Ангийн дүрмээр аврагдсан нь",
    description: `МӨРӨӨДЛИЙН ЖИГҮҮР боловсролын төвөөс эрхлэн гаргадаг 7 хоног
                тутмын подкаст. Манай багш нарын өрөөнд яригддаг төрөл бүрийн
                сургалтын арга зүй, аргачлалын тухай яриануудаа та нартайгаа
                хуваалцъя.`,
    video_url: "https://www.youtube.com/watch?v=hNeOW20ruds",
    teacher1_name: "Teacher: Hulan",
    teacher2_name: "Teacher: Suvdaa",
    teacher1_img_url: "/images/profile/hulan.png",
    teacher2_img_url: "/images/profile/suvdaa.png",
  },
  {
    id: 2,
    title:
      "Жишээ ярья подкаст ДУГААР 2. Багшийн сэтгэл хөдлөл хүүхдэд нөлөөлөх нь",
    description: `МӨРӨӨДЛИЙН ЖИГҮҮР боловсролын төвөөс эрхлэн гаргадаг 7 хоног
                тутмын подкаст. Манай багш нарын өрөөнд яригддаг төрөл бүрийн
                сургалтын арга зүй, аргачлалын тухай яриануудаа та нартайгаа
                хуваалцъя.`,
    video_url: "https://www.youtube.com/watch?v=c4GcLIpkGE4&t=175s",
    teacher1_name: "Teacher: Hulan",
    teacher2_name: "Teacher: Suvdaa",
    teacher1_img_url: "/images/profile/hulan.png",
    teacher2_img_url: "/images/profile/suvdaa.png",
  },
  {
    id: 3,
    title: "Жишээ ярья подкаст ДУГААР 3: Алдсан ч Айхгүй",
    description: `МӨРӨӨДЛИЙН ЖИГҮҮР боловсролын төвөөс эрхлэн гаргадаг 7 хоног
                тутмын подкаст. Манай багш нарын өрөөнд яригддаг төрөл бүрийн
                сургалтын арга зүй, аргачлалын тухай яриануудаа та нартайгаа
                хуваалцъя.`,
    video_url: "https://www.youtube.com/watch?v=gElKj9xcuzM&t=263s",
    teacher1_name: "Teacher: Naraa",
    teacher2_name: "Teacher: Suvdaa",
    teacher1_img_url: "/images/profile/naraa.png",
    teacher2_img_url: "/images/profile/suvdaa.png",
  },
  {
    id: 4,
    title: "Жишээ ярья подкаст ДУГААР 4. Тархийг нь ХОРДУУЛАХГҮЙГЭЭР сургая",
    description: `МӨРӨӨДЛИЙН ЖИГҮҮР боловсролын төвөөс эрхлэн гаргадаг 7 хоног
                тутмын подкаст. Манай багш нарын өрөөнд яригддаг төрөл бүрийн
                сургалтын арга зүй, аргачлалын тухай яриануудаа та нартайгаа
                хуваалцъя.`,
    video_url: "https://www.youtube.com/watch?v=UPS09TY1vVg&t=21s",
    teacher1_name: "Teacher: Hulan",
    teacher2_name: "Teacher: Badamtsetseg",
    teacher1_img_url: "/images/profile/hulan.png",
    teacher2_img_url: "/images/profile/badamtsetseg.png",
  },
];

const DownloadFileDetail: React.FC = () => {
  return (
    <MainLayout>
      <div
        style={{
          backgroundColor: "#002333",
          color: "#fff",
          minHeight: "calc(100vh - 64px)",
        }}
      >
        <Space
          direction="vertical"
          size="middle"
          style={{
            width: "100%",
            paddingTop: "104px",
            maxWidth: 1352,
            margin: "0 auto",
            display: "flex",
            backgroundColor: "#002333",
            alignItems: "center",
          }}
        >
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={8} lg={15} xl={15}></Col>
          </Row>
        </Space>
      </div>
    </MainLayout>
  );
};

export default DownloadFileDetail;
